import {
  Array,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union
} from "runtypes";

const AdEntityTypeValidator = Union(Literal("user"), Literal("group"));

export type AdEntityType = Static<typeof AdEntityTypeValidator>;

const AdEntityValidator = Record({
  // id: Number, // FE doesn't care; main PK is username anyway
  displayName: String,
  username: String,
  email: String,
  type: AdEntityTypeValidator
});

export type AdEntity = Static<typeof AdEntityValidator>;

export const AgendaValidator = Record({
  id: Number,
  title: String,
  publishedDate: String,
  owner: AdEntityValidator,
  admins: Array(AdEntityValidator),
  fullViewers: Array(AdEntityValidator),
  limitedViewers: Array(AdEntityValidator)
});

export type Agenda = Static<typeof AgendaValidator>;
