import React, { useMemo } from "react";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { Label, Text } from "common-components";
import { format, colors } from "utils";
import "./ComModalBrandSalesDemand.scss";
import {
  CommercialBrandSalesDemandSection,
  CommercialBrandSalesDemandSectionTable,
  CommercialBrandInactiveSection
} from "api";
import {
  ComposedLineChartComponent,
  LineType
} from "@gitlab-rtsensing/component-library";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";

type Props = {
  data:
    | CommercialBrandSalesDemandSection
    | CommercialBrandInactiveSection
    | undefined
    | null;
};

const TableSection = (props: CommercialBrandSalesDemandSectionTable) => {
  return (
    <div className="table-section">
      <Text strong className="section-title">
        {props.title}
      </Text>
      {props.rows.map((row, i) => (
        <div className="table-row" key={i}>
          {row.items.map((item, i) => (
            <div className="table-item" key={i}>
              <Label>{item.label}</Label>
              <div>
                <Text type={item.status}>{item.value}</Text>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const namespace = "rts-pa-commercial-modal-brand-net-sales-demand";

const ComModalBrandSalesDemand = (props: Props) => {
  const data = useMemo(() => {
    if (props.data?.status === "inactive" || !props.data?.chart) {
      return [];
    }

    return props.data.chart.map(item => ({
      label: item.label || undefined,
      forecast: item.forecast || undefined,
      value: item.value || undefined
    }));
  }, [props.data]);

  if (!props.data) {
    return <></>;
  } else if (
    props.data?.status === "inactive" ||
    (!props.data.chart.length && !props.data.tables.length)
  ) {
    return (
      <AccordionWithStatus
        title={props.data.title}
        tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
        status="gray"
      />
    );
  }

  const firstIndex = 0;
  const middleIndex = data.length > 12 
      ? Math.ceil(data.length / 2) - 2
      : Math.ceil(data.length / 2) - 1;
  const lastIndex = data.length - 1;

  const customTicks = [
    data[firstIndex]?.label || "",
    data[middleIndex]?.label || "",
    data[lastIndex]?.label || ""
  ];

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={props.data.title}
      className={`rts-pa-commercial-modal-brand-${props.data.title}`}
      externalLinks={props.data.externalLinks}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      accordionSyncKey="CommercialModal"
    >
      <div className={namespace}>
        {props.data.chartTitle && (
          <div className={`${namespace}-chart-title`}>
            {props.data.chartTitle}
          </div>
        )}
        <ComposedLineChartComponent
          data={data}
          xAxisKey="label"
          dataKeys={[
            { label: "value", dot: false, color: colors.primaryBlue },
            {
              label: "forecast",
              dot: false,
              color: colors.primaryBlue,
              type: LineType.DASH,
              strokeWidth: 2
            }
          ]}
          chartHeight={175}
          useTooltip
          yAxisWidth={43}
          tick
          ticks={customTicks}
          dataValueFormatter={(
            value: string | number | Array<string | number>
          ) => {
            if (typeof value === "number") {
              return format.simple(value, { padZeroIfDecimal: true });
            }

            return value.toString();
          }}
        />
        {props.data.tables.map((t, i) => (
          <TableSection key={i} title={t.title} rows={t.rows} />
        ))}
      </div>
    </AccordionWithStatus>
  );
};

export default ComModalBrandSalesDemand;
