import React, { useMemo, useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import "./AgendaGridLineItem.scss";
import { AgendaItemV2 } from "api/common-v2";
import { StatusIcon, Text } from "common-components";
import classnames from "classnames";
import { EditIcon } from "@opsdti-global-component-library/amgen-design-system";
import DragIcon from "icons/drag-icon";
import { PermissionsContext } from "global/permissions";
import pages from "pages/pages";
import { useNavigate } from "react-router-dom";
import { useGridModeHelper } from "global/use-grid-mode-helper";

export type Props = {
  agendaItem: AgendaItemV2;
  index: number;
};

const namespace = "rts-pa-agenda-grid-line-item";

const AgendaGridLineItem = (props: Props) => {
  const navigate = useNavigate();
  const gridPerms = useContext(PermissionsContext).grid;
  const gridMode = useGridModeHelper();

  const onItemClick = useMemo(() => {
    if (!gridMode.isViewMode) {
      return undefined;
    }

    let isClickable = false;

    switch (props.agendaItem.archetype) {
      case "commercial":
        isClickable = gridPerms.commercialModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.commercialModal.go(props.agendaItem.id));
          };
        }
        break;
      case "pipeline":
        isClickable = gridPerms.pipelineModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.pipelineModal.go(props.agendaItem.id));
          };
        }
        break;
      case "talent_dinb":
        isClickable = gridPerms.talentDinbModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.talentDinbModal.go(props.agendaItem.id));
          };
        }
        break;
      case "esg":
        isClickable = gridPerms.esgModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.esgModal.go(props.agendaItem.id));
          };
        }
        break;
      case "standalone":
        isClickable = gridPerms.standaloneModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.standaloneModal.go(props.agendaItem.id));
          };
        }
        break;
      case "biosimilars":
        isClickable = gridPerms.biosimilarsModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.biosimilarsModal.go(props.agendaItem.id));
          };
        }
        break;
    }

    return undefined;
  }, [
    gridMode,
    props.agendaItem,
    gridPerms.standaloneModal,
    gridPerms.commercialModal,
    gridPerms.pipelineModal,
    gridPerms.talentDinbModal,
    gridPerms.esgModal,
    gridPerms.biosimilarsModal,
    navigate
  ]);

  const containerClassName = classnames(`${namespace}-view-container`, {
    clickable: !!onItemClick
  });

  const statusNameContainer = (
    <div className={containerClassName} onClick={onItemClick}>
      <div className={`${namespace}-item-status`}>
        {/*NOTE JCG: "•" sometimes added through Home.scss when printing */}
        {props.agendaItem.status ? (
          <StatusIcon status={props.agendaItem.status} />
        ) : (
          <div>•</div>
        )}
      </div>
      <Text>{props.agendaItem.name}</Text>
    </div>
  );

  if (gridMode.isViewMode) {
    return <div className={namespace}>{statusNameContainer}</div>;
  }

  if (gridMode.isEditMode) {
    return (
      <div className={namespace}>
        {statusNameContainer}
        <div
          className={`${namespace}-pencil`}
          onClick={() => {
            navigate(pages.grid.edit.editItemModal.go(props.agendaItem.id));
          }}
        >
          <div className={`${namespace}-pencil-icon-container`}>
            <EditIcon height={12} width={12} />
          </div>
        </div>
      </div>
    );
  }

  //if gridMode.isRearrangeMode
  return (
    <Draggable
      draggableId={props.agendaItem.id.toString()}
      isDragDisabled={!gridMode.isRearrangeMode}
      index={props.index + 1}
      key={props.agendaItem.id}
    >
      {(provided, snapshot) => {
        const rootClassName = classnames(namespace, {
          isDragging: snapshot.isDragging,
          isDraggable: gridMode.isRearrangeMode
        });

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            className={rootClassName}
          >
            {statusNameContainer}
            <div className={`${namespace}-drag`} {...provided.dragHandleProps}>
              <DragIcon height={10} />
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default AgendaGridLineItem;
