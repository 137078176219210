import React from "react";
import "./ComModalBrandIdnAccountWins.scss";
import {
  CommercialBrandIdnAccountWinsSection,
  CommercialBrandInactiveSection
} from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";

type Props = {
  data:
    | CommercialBrandIdnAccountWinsSection
    | CommercialBrandInactiveSection
    | undefined
    | null;
};

const ComModalBrandIdnAccountWins = (props: Props) => {
  const title = "US Integrated Delivery Network (IDN) Account Wins";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return (
      <AccordionWithStatus
        title={title}
        tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
        status="gray"
      />
    );
  }

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={title}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      className="rts-pa-commercial-modal-brand-idn-account-wins"
      accordionSyncKey="CommercialModal"
    />
  );
};

export default ComModalBrandIdnAccountWins;
