import { BiosimilarPipelineGroupValidator } from "./biosimilar-item/biosimilar-pipeline-group";
import {
  ExternalLinkValidator,
  SummaryInfoValidator,
  OwnersValidator
} from "./common";
import { Array, Number, Record, Static } from "runtypes";

export const BiosimilarItemValidator = Record({
  agendaItemId: Number,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  pipeline: BiosimilarPipelineGroupValidator,
  externalLinks: Array(ExternalLinkValidator)
});

export type BiosimilarItem = Static<typeof BiosimilarItemValidator>;
