import React from "react";
import "./KeyEventsCalendarRowMilestoneRange.scss";
import { MilestoneRange } from "components/key-events-calendar/calendarTypes";
import { Text } from "common-components";

export const namespace = "rts-pa-key-events-calendar-row-milestone-range";

type Props = {
  milestone: MilestoneRange;
};

export default function KeyEventsCalendarRowMilestoneRange(
  props: Props
): JSX.Element {
  return (
    <div
      className={namespace}
      style={{
        gridColumn: `y${props.milestone.startYear}q${props.milestone.startQuarter} / y${props.milestone.endYear}q${props.milestone.endQuarter}`
      }}
    >
      <div className={`${namespace}-container`}>
        <Text>{props.milestone.name}</Text>
      </div>
    </div>
  );
}
