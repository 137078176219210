import { Record, Static } from "runtypes";
import { InactiveSectionValidator } from "../common";
import { CommercialCommonSalesDemandLiteSectionValidator } from "./commercial-common";

export const CommercialGlobalNetSalesSectionValidator = Record({
  ytdVarianceVsFcst: CommercialCommonSalesDemandLiteSectionValidator.Or(
    InactiveSectionValidator
  ).nullable()
});

export type CommercialGlobalNetSalesSection = Static<
  typeof CommercialGlobalNetSalesSectionValidator
>;
