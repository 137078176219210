import axios from "axios";
import { Array, Record, Static, String } from "runtypes";
import { UserValidator } from "../common";
import { SubmissionStatusValidator } from "api/submission/submission-common";
import { getAgendaId } from "utils/multitenancy";

const SubmissionsTableItemValidator = Record({
  id: String,
  createdDate: String,
  submittedDate: String.nullable(),
  updatedDate: String,
  title: String,
  status: SubmissionStatusValidator,
  assignee: UserValidator
});

export type SubmissionsTableItem = Static<typeof SubmissionsTableItemValidator>;

const SubmissionsTableValidator = Array(SubmissionsTableItemValidator);

export type SubmissionsTable = Static<typeof SubmissionsTableValidator>;

export const getSubmissionsTableV2 = async (
): Promise<SubmissionsTable> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<SubmissionsTable>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/submission`
  );

  SubmissionsTableValidator.check(result.data);

  return result.data;
};
