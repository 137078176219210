import { Array, Number, Record, Static, String } from "runtypes";
import {
  OwnersValidator,
  ExternalLinkValidator,
  SummaryInfoValidator
} from "./common";
import {
  NextApprovalLaunchE2lValidator,
  NextPriorityMilestoneValidator,
  PipelineStudyEnrollmentValidator
} from "./pipeline/pipeline-project";

export const PipelineItemValidator = Record({
  title: String,
  agendaItemId: Number,
  summaryInfo: SummaryInfoValidator,
  href: String,
  owners: OwnersValidator,
  nextApprovalLaunchE2l: NextApprovalLaunchE2lValidator,
  nextPriorityMilestone: NextPriorityMilestoneValidator,
  studyEnrollments: Array(PipelineStudyEnrollmentValidator),
  externalLinks: Array(ExternalLinkValidator)
});

export type PipelineItem = Static<typeof PipelineItemValidator>;
