import {
  AdvancedTooltipValidator,
  StatusValidator,
  StatusWithAcceleratedValidator
} from "api/common";
import { Array, Record, Static, String } from "runtypes";

const BiosimilarsPipelinePlannedMarketReadinessProjectV2 = Record({
  id: String,
  name: String,
  status: StatusWithAcceleratedValidator,
  statusTooltip: AdvancedTooltipValidator,
  readinessDate: String,
  region: String
});

export const BiosimilarsPipelinePlannedMarketReadinessSectionValidatorV2 =
  Record({
    status: StatusValidator,
    tooltip: AdvancedTooltipValidator,
    projects: Array(BiosimilarsPipelinePlannedMarketReadinessProjectV2)
  });

export type BiosimilarsPipelinePlannedMarketReadinessSectionV2 = Static<
  typeof BiosimilarsPipelinePlannedMarketReadinessSectionValidatorV2
>;
