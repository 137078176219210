import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { RouteObject, matchRoutes, useLocation } from "react-router-dom";
import {
  PermissionsCollection,
  hasPermissions
} from "common-components/permissions-wrapper/permissions-wrapper";
import { AgendaItemV2, ArchetypeV2 } from "api/common-v2";
import { getAgendaItemsV2 } from "api/getAgendaItemsV2";

type Result = {
  agendaItemId: number;
  agendaItem: AgendaItemV2;
  title: string;
  path: string;
  enabled: boolean;
};

const defaultResponse: Result = {
  agendaItem: {} as AgendaItemV2,
  agendaItemId: 0,
  title: "",
  path: "",
  enabled: false
};

export function useModalHelperV2(
  paths: string | string[],
  permissions: PermissionsCollection, //NOTE: if passing in array, will map perms array index to path index for validation
  archetype?: ArchetypeV2
): Result {
  const [response, setResponse] = useState<Result>(defaultResponse);

  const { data } = useQuery(["agenda-items", false], () =>
    getAgendaItemsV2(false)
  );

  const location = useLocation();

  useEffect(() => {
    const returnDefault = () => {
      if (!response.enabled) {
        return; //already default
      }

      setResponse({
        ...response,
        enabled: false
      });

      setTimeout(() => {
        setResponse(defaultResponse);
      }, 500);
    };

    // check perms now, if both perms+path are not arrays (if both arrays, check perms based on matching path)
    if (
      !Array.isArray(permissions) ||
      (Array.isArray(permissions) && !Array.isArray(paths))
    ) {
      if (!hasPermissions(permissions)) {
        return returnDefault();
      }
    }

    //check paths
    let routes: RouteObject[];
    if (Array.isArray(paths)) {
      routes = paths.map(p => ({ path: p }));
    } else {
      routes = [{ path: paths }];
    }

    const matches = matchRoutes(routes, location);
    if (!matches) {
      return returnDefault();
    }

    const path = matches[0].route.path || "";

    //if permissions are array, check permissions based on path-matching-index
    if (Array.isArray(permissions)) {
      const pathIndex = paths.indexOf(path);

      if (pathIndex >= 0) {
        if (permissions.length - 1 < pathIndex) {
          throw new Error(
            "In useModalHelper, if passing in an array for both permissions and paths, they must match in length"
          );
        } else if (!hasPermissions(permissions[pathIndex])) {
          return returnDefault();
        }
      }
    }

    const { agendaItemId } = matches[0].params;

    //get agendaItemId
    let agendaItemIdNum: number;
    if (!agendaItemId) {
      //ok if no agendaItemId needed
      return setResponse({
        ...defaultResponse,
        path: path,
        enabled: true
      });
    } else {
      agendaItemIdNum = parseInt(agendaItemId);

      //if agendaItemId is NaN, ignore request
      if (Number.isNaN(agendaItemIdNum)) {
        return returnDefault();
      }
    }

    //check agendaItemId exists on grid
    if (!data) {
      return returnDefault();
    }

    for (const group of data.groups) {
      for (const item of group.items) {
        if (item.id === agendaItemIdNum) {
          //if wrong archetype, ignore request
          if (archetype && item.archetype !== archetype) {
            return returnDefault();
          } else {
            //success
            return setResponse({
              agendaItem: item,
              agendaItemId: item.id,
              title: item.name,
              path: path,
              enabled: true
            });
          }
        }
      }
    }

    returnDefault();
  }, [data, location]); // eslint-disable-line react-hooks/exhaustive-deps

  return response;
}
