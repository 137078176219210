import React from "react";
import Accordion, {
  AccordionProps
} from "common-components/accordion/Accordion";
import "./AccordionWithStatus.scss";
import { ExternalLink, Status } from "api/common";
import StatusIcon from "common-components/status-icon/StatusIcon";
import classNames from "classnames";
import Tooltip from "common-components/tooltip/Tooltip";
import ModalExternalLinks from "common-components/modal-external-links/ModalExternalLinks";
import { Title } from "common-components";

export interface AccordionWithStatusProps
  extends Omit<AccordionProps, "children"> {
  status: Status;
  externalLinks?: ExternalLink[];
  areLinksSmall?: boolean;
  tooltipContent?: JSX.Element | null;
  children?: JSX.Element | JSX.Element[];
}

export const AccordionWithStatus = (
  props: AccordionWithStatusProps
): JSX.Element => {
  const namespace = "rts-pa-accordion-with-status";

  const title = (
    <div className={`${namespace}-header`}>
      <StatusIcon status={props.status} />
      <Title level={3} className={`${namespace}-header-title`}>
        {props.title}
      </Title>
      <Tooltip>{props.tooltipContent}</Tooltip>
    </div>
  );

  const className = classNames(namespace, props.className, {
    [`${namespace}-placeholder`]: !props.children
  });

  const classNameLinks = classNames({
    "small-links": props.areLinksSmall
  });

  if (props.children) {
    return (
      <Accordion {...props} title={title} className={className}>
        <div className={`${namespace}-content`}>{props.children}</div>
        <ModalExternalLinks
          externalLinks={props.externalLinks}
          className={classNameLinks}
          buttonType="secondary"
        />
      </Accordion>
    );
  } else {
    return (
      <Accordion
        {...props}
        title={title}
        isOpen={false} //"placeholder" accordion; always closed
        className={className}
        accordionSyncKey={undefined}
        startOpen={false}
      >
        <></>
      </Accordion>
    );
  }
};

export default Accordion;
