import { Record, Static } from "runtypes";
import { BiosimilarsPipelinePlannedMarketReadinessSectionValidatorV2 } from "./biosimilars-planned-market-readiness-section-v2";

export const BiosimilarsPipelineGroupValidatorV2 = Record({
  plannedMarketReadiness:
    BiosimilarsPipelinePlannedMarketReadinessSectionValidatorV2
});

export type BiosimilarsPipelineGroupV2 = Static<
  typeof BiosimilarsPipelineGroupValidatorV2
>;
