export const colors = {
  //grayscale
  white: "#fff",
  gray100: "#fff",
  gray200: "#f9f9f9",
  gray300: "#e3e3e3",
  gray400: "#c4c4c4",
  gray500: "#9c9c9c",
  gray600: "#585858",
  gray700: "#2f2f2f",
  black: "#000",

  //semantic colors
  green: "#008533",
  red: "#dc3545",
  yellow: "#d07804",

  //accent colors
  teal: "#15a2ac",
  purple: "#5610eb",
  lightBlue: "#7fb0e0",
  pinkAccent: "#e377c2",
  yellowAccent: "#fdb81e",

  //base colors
  primary: "#3773d2",
  primaryBlue: "#0063c3",
  secondary: "#585858",
  tertiaryBlue: "#004D99",
  success: "#008533",
  info: "#15a2ac",
  warning: "#d07804",
  danger: "#dc3545",
  light: "#fff",
  dark: "#2f2f2f"
};

export default colors;
