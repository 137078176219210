import React from "react";
import "./ModalExternalLinksV2.scss";
import { Link } from "api/common-v2";
import {
  LinkExternalIcon,
  Button,
  ButtonType
} from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";

const namespace = "rts-pa-modal-external-links-v2";

export type Props = {
  className?: string;
  links: Link[] | undefined;
  buttonType: ButtonType;
};

export const ModalExternalLinksV2 = (props: Props) => {
  if (!props.links?.length) {
    return <></>;
  }

  const className = classNames(namespace, props.className);

  return (
    <div className={className}>
      {props.links.map((link) => (
        <Button
          key={link.id}
          type={props.buttonType}
          text={link.label}
          onClick={() => window.open(link.href, "_blank")}
          icon={
            link.isSensingExternal ? (
              <LinkExternalIcon width={12} height={12} />
            ) : undefined
          }
        />
      ))}
    </div>
  );
};

export default ModalExternalLinksV2;
