import React from "react";
import "./AIModalSectionPipelineProject.scss";
import {
  AccordionWithStatus,
  getAdvancedTooltipContent
} from "common-components";
import { AgendaItemSectionPipelineProject } from "api/agenda-item/sections";
import OwnersContainer from "common-components/owners-container/OwnersContainer";

const namespace = "rts-pa-agenda-item-modal-section-pipeline-project";

type Props = {
  section: AgendaItemSectionPipelineProject;
};

export const AIModalSectionPipelineProject = (props: Props) => {
  return (
    <AccordionWithStatus
      status={props.section.status}
      title={props.section.title}
      className={namespace}
      accordionSyncKey="AgendaItemModal"
      accordionSyncId={props.section.id}
      externalLinks={props.section.links}
      tooltipContent={getAdvancedTooltipContent(props.section.tooltip)}
    >
      <OwnersContainer owners={props.section.owners} hideOnEmpty />
      <>
        {/* {props.project.sections.map((s, i) => (
          <ComModalPipelineProjectSection key={i} data={s} />
        ))}

        {props.project.studyEnrollments && (
          <EnrollmentSection
            enrollments={props.project.studyEnrollments}
            title="Priority Study Critical Path Enrollment"
          />
        )} */}
      </>
    </AccordionWithStatus>
  );
};

export default AIModalSectionPipelineProject;
