import { Array, Boolean, Number, Record, Static, String } from "runtypes";

export const CalendarRowItemMilestoneValidator = Record({
  id: Number,
  calendarRowId: Number,
  milestoneLinkId: String.nullable(),
  name: String, // title
  startDate: String,
  endDate: String.nullable(),
  calendarKeyId: Number,
  text: String, // additional info
  isVisible: Boolean.nullable(), // "new" if null
  isVisibleToOfficers: Boolean
});

export type CalendarRowItemMilestone = Static<
  typeof CalendarRowItemMilestoneValidator
>;

export const CalendarRowItemValidator = Record({
  id: Number,
  calendarRowLinkId: String.nullable(),
  name: String,
  order: Number,
  isVisibleToOfficers: Boolean,
  automaticMilestones: Array(CalendarRowItemMilestoneValidator),
  manualMilestones: Array(CalendarRowItemMilestoneValidator)
});

export type CalendarRowItem = Static<typeof CalendarRowItemValidator>;
