import { useQuery } from "@tanstack/react-query";
import {
  getCheckoutStatus as getCheckoutStatusV2,
  checkout as checkoutApiV2,
  checkin as checkinApiV2
} from "api/conflict-management-v2";
import { useState } from "react";

export const useConflictManagement = () => {
  const [isCheckOutInLoadingV2, setIsCheckOutInLoadingV2] = useState(false);
  const { refetch: refreshCheckoutStatusV2 } = useQuery(
    ["checkout-status"],
    getCheckoutStatusV2,
    {
      enabled: false
    }
  );

  const { refetch: checkoutV2 } = useQuery(["checkout"], checkoutApiV2, {
    enabled: false,
    retry: false
  });

  const { refetch: checkinV2 } = useQuery(["checkin"], checkinApiV2, {
    enabled: false,
    retry: false
  });

  return {
    isCheckOutInLoading: isCheckOutInLoadingV2,
    setIsCheckOutInLoading: setIsCheckOutInLoadingV2,
    refreshCheckoutStatus: refreshCheckoutStatusV2,
    checkout: checkoutV2,
    checkin: checkinV2
  };
};
