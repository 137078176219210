import axios from "axios";
import { EsgItemV2, EsgItemValidatorV2 } from "./esg-v2";
import { ArchetypeV2 } from "./common-v2";
import { getAgendaId } from "utils/multitenancy";
import { PipelineItemV2, PipelineItemValidatorV2 } from "./getPipelineItemV2";
import {
  CommercialItemV2,
  CommercialItemValidatorV2
} from "./getCommercialItemV2";
import {
  BiosimilarsItemV2,
  BiosimilarsItemValidatorV2
} from "./biosimilars-v2";
import { TalentDinbV2, TalentDinbValidatorV2 } from "./getTalentDinbV2";

type GetAgendaItemCachedResultV2<T extends ArchetypeV2> = T extends "commercial"
  ? CommercialItemV2
  : T extends "pipeline"
  ? PipelineItemV2
  : T extends "talent_dinb"
  ? TalentDinbV2
  : T extends "esg"
  ? EsgItemV2
  : T extends "biosimilars"
  ? BiosimilarsItemV2
  : never;

export const getAgendaItemCachedV2 = async <T extends ArchetypeV2>(
  archetypeV2: T,
  id: number
): Promise<GetAgendaItemCachedResultV2<T>> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<GetAgendaItemCachedResultV2<T>>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/cache/agenda_item?agenda_item_id=${id}`
  );

  // const result = {
  //   data: TestData as unknown as CommercialItem
  // };

  switch (archetypeV2) {
    case "commercial":
      CommercialItemValidatorV2.check(result.data);
      break;
    case "pipeline":
      PipelineItemValidatorV2.check(result.data);
      break;
    case "talent_dinb":
      TalentDinbValidatorV2.check(result.data);
      break;
    case "esg":
      EsgItemValidatorV2.check(result.data); //EsgItem2Validator to come
      break;
    case "biosimilars":
      BiosimilarsItemValidatorV2.check(result.data);
      break;
  }

  return result.data;
};

export const putOfficersGridCacheV2 = async (): Promise<void> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  await axios.put(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/cache/update_officers_grid`
  );
};
