import React from "react";
import "./ModalExternalLinks.scss";
import { ExternalLink } from "api/common";
import {
  LinkExternalIcon,
  Button,
  ButtonType
} from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";

const namespace = "rts-pa-modal-external-links";

export type Props = {
  className?: string;
  externalLinks: ExternalLink[] | undefined;
  buttonType: ButtonType;
};

export const ModalExternalLinks = (props: Props) => {
  if (!props.externalLinks?.length) {
    return <></>;
  }

  const className = classNames(namespace, props.className);

  return (
    <div className={className}>
      {props.externalLinks.map((l, i) => (
        <Button
          key={i}
          type={props.buttonType}
          text={l.label}
          onClick={() => window.open(l.href, "_blank")}
          icon={
            l.isSensingExternal ? (
              <LinkExternalIcon width={12} height={12} />
            ) : undefined
          }
        />
      ))}
    </div>
  );
};

export default ModalExternalLinks;
