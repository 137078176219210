import { Array, Literal, Static } from "runtypes";
import { AgendaItemSectionValidator } from "../agenda-item-common";
import { ChartItemValidator, ExternalLinkValidator } from "api/common";

export const AgendaItemSectionTurnoverValidator =
  AgendaItemSectionValidator.extend({
    type: Literal("turnover"),
    chart: Array(ChartItemValidator),
    links: Array(ExternalLinkValidator)
  });

export type AgendaItemSectionTurnover = Static<
  typeof AgendaItemSectionTurnoverValidator
>;
