import React from "react";
import "./TalentDinbModalTalentMetricsV2.scss";
import ModalSection from "common-components/modal-section/ModalSection";
import { TalentDinbTalentSectionV2 } from "api/talent-dinb-v2/talent-dinb-talent-v2";
import TalentDinbModalTalentAmgenOverallEngagementV2 from "./amgen-overall-engagement-v2/TalentDinbModalTalentAmgenOverallEngagementV2";
import TalentDinbModalTalentAmgenTotalTurnoverV2 from "./amgen-total-turnover-v2/TalentDinbModalTalentAmgenTotalTurnoverV2";

type Props = {
  data: TalentDinbTalentSectionV2 | undefined | null;
};

const TalentDinbModalTalentMetricsV2 = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection
      title="Talent Metrics"
      className="rts-pa-talent-dinb-modal-talent-metrics-v2"
    >
      <div className="rts-pa-talent-dinb-modal-talent-metrics-container-v2">
        <TalentDinbModalTalentAmgenOverallEngagementV2
          data={props.data.amgenOverallEngagement}
        />
        <TalentDinbModalTalentAmgenTotalTurnoverV2
          data={props.data.amgenTotalTurnover}
        />
      </div>
    </ModalSection>
  );
};

export default TalentDinbModalTalentMetricsV2;
