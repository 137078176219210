

import {  Record, Static } from "runtypes"
import { TotalCarbonWasteWaterReductionOffsetValidator } from "./total-carbon-waste-water-reduction-offset";

export const EnvironmentalSustainabilityValidator = Record({
    totalCarbonWasteWaterReductionOffset: TotalCarbonWasteWaterReductionOffsetValidator
});

export type EnvironmentalSustainability = Static<typeof EnvironmentalSustainabilityValidator>;

