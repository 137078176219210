import React from "react";
import "./ToggleButton.scss";
import { Label } from "common-components";

type Props = {
  firstLabel?: string;
  secondLabel?: string;
  checked: boolean | undefined;
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ToggleButton = (props: Props) => {
  return (
    <span className="rts-pa-toggle-button">
      <Label colorVariant={!props.checked ? "selected" : "tertiary"}>
        {props.firstLabel}
      </Label>
      <input
        type="checkbox"
        checked={props.checked}
        onChange={() => props.onClick(!props.checked)}
      />
      <Label colorVariant={props.checked ? "selected" : "tertiary"}>
        {props.secondLabel}
      </Label>
    </span>
  );
};

export default ToggleButton;
