import { Number, Record, Static, String, Array } from "runtypes";
import {
  LinkValidator,
  OwnersValidator,
  SummaryInfoValidator,
  ArchetypeValidatorV2
} from "./common-v2";
import { CommercialPipelineSectionValidator } from "./commercial-item/commercial-pipeline";
import { CommercialSupplySectionValidator } from "./commercial-item/commercial-supply";
import { CommercialPeopleSectionValidator } from "./commercial-item/commercial-people";
import { CommercialBrandSectionValidator } from "./commercial-item/commercial-brand";

export const CommercialItemValidatorV2 = Record({
  title: String,
  archetype: ArchetypeValidatorV2,
  triggers: Array(String),
  agendaItemId: Number,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  brand: CommercialBrandSectionValidator.nullable(),
  pipeline: CommercialPipelineSectionValidator.nullable(),
  supply: CommercialSupplySectionValidator.nullable(),
  people: CommercialPeopleSectionValidator.nullable(),
  links: Array(LinkValidator)
});

export type CommercialItemV2 = Static<typeof CommercialItemValidatorV2>;
