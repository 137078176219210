import {
  AdvancedTooltipValidator,
  ExternalLinkValidator,
  ChartItemValidator,
  StatusValidator,
  InactiveSectionValidator
} from "api/common";
import { Array, Record, Static } from "runtypes";

const TalentDinbTalentAmgenOverallEngagementValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  externalLinks: Array(ExternalLinkValidator)
});

export type TalentDinbTalentAmgenOverallEngagement = Static<
  typeof TalentDinbTalentAmgenOverallEngagementValidator
>;

const TalentDinbTalentAmgenTotalTurnoverValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator,
  chartTooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  externalLinks: Array(ExternalLinkValidator)
});

export type TalentDinbTalentAmgenTotalTurnover = Static<
  typeof TalentDinbTalentAmgenTotalTurnoverValidator
>;

export const TalentDinbTalentSectionValidator = Record({
  amgenOverallEngagement: TalentDinbTalentAmgenOverallEngagementValidator.Or(
    InactiveSectionValidator
  ),
  amgenTotalTurnover: TalentDinbTalentAmgenTotalTurnoverValidator.Or(
    InactiveSectionValidator
  )
});

export type TalentDinbTalentSection = Static<
  typeof TalentDinbTalentSectionValidator
>;
