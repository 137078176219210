import { Array, Number, Record, Static, String } from "runtypes";
import {
  StatusValidator,
  AdvancedTooltipValidator,
  SummaryInfoValidator,
  OwnersValidator,
  ExternalLinkValidator
} from "./common";
import axios from "axios";
//import * as TestData from "__fixtures__/horizon/horizon-item.json";

//#region Common
const HorizonSalesDemandLiteSectionValidator = Record({
  title: String,
  status: StatusValidator,
  value: String,
  tooltip: AdvancedTooltipValidator.nullable()
});
//#endregion

//#region Sales Demand Lite Groups
const HorizonSalesDemandLiteOnlyGroupValidator = Record({
  salesDemandLiteSections: Array(HorizonSalesDemandLiteSectionValidator)
});

export type HorizonSalesDemandLiteOnlyGroup = Static<
  typeof HorizonSalesDemandLiteOnlyGroupValidator
>;
//#endregion

//#region Gray-Box Groups
const HorizonSummaryInfoOnlyGroupValidator = Record({
  summaryInfo: SummaryInfoValidator
});

export type HorizonSummaryInfoOnlyGroup = Static<
  typeof HorizonSummaryInfoOnlyGroupValidator
>;
//#endregion

const HorizonItemValidator = Record({
  title: String,
  agendaItemId: Number,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  externalLinks: Array(ExternalLinkValidator),
  globalNetSales: HorizonSalesDemandLiteOnlyGroupValidator,
  usBrand: HorizonSalesDemandLiteOnlyGroupValidator,
  exUsBrand: HorizonSummaryInfoOnlyGroupValidator,
  pipelineSupply: HorizonSummaryInfoOnlyGroupValidator,
  people: HorizonSummaryInfoOnlyGroupValidator,
  synergies: HorizonSummaryInfoOnlyGroupValidator
});

export type HorizonItem = Static<typeof HorizonItemValidator>;

export const getHorizonItem = async (id: number): Promise<HorizonItem> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<HorizonItem>(
    `${REACT_APP_DOMAIN}/api/v1/horizon`
  );

  HorizonItemValidator.check(result.data);

  return result.data;
};
