import React from "react";
import ModalSection from "common-components/modal-section/ModalSection";
import EsgTotalCarbonWasteWaterReductionOffsetV2 from "./total-carbon-waste-water-reduction-offset-v2/EsgTotalCarbonWasteWaterReductionOffsetV2";
import { EnvironmentalSustainabilityV2 } from "api/esg-item-v2/esg-v2";
import "./EnvironmentalSustainabilityMetricsV2.scss";

type Props = {
  data?: EnvironmentalSustainabilityV2;
};

const namespace =
  "rts-pa-agenda-esg-modal-environmental-sustainability-metrics-v2";

const EnvironmentalSustainabilityMetricsV2 = ({ data }: Props) => {
  if (!data) {
    return <></>;
  }

  return (
    <ModalSection
      className={namespace}
      title="Environmental Sustainability Metrics"
    >
      <div className={`${namespace}-container`}>
        <EsgTotalCarbonWasteWaterReductionOffsetV2
          data={data.totalCarbonWasteWaterReductionOffset}
        />
      </div>
    </ModalSection>
  );
};
export default EnvironmentalSustainabilityMetricsV2;
