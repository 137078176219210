import { useQuery } from "@tanstack/react-query";
import { EsgItemV2 } from "api/esg-v2";
import { getAgendaItemCachedV2 } from "api/cache-v2";
import OwnersContainerV2 from "common-components/owners-container-v2/OwnersContainerV2";
import PaModal from "common-components/pa-modal/PaModal";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import { PermissionsContext } from "global/permissions";
import pages from "pages/pages";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./EsgModalV2.scss";
import EnvironmentalSustainabilityMetricsV2 from "./environmental-sustainability-metrics-v2/EnvironmentalSustanabilityMetricsV2";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import SubmissionRequestV2 from "common-components/submission-request-v2/SubmissionRequestV2";
import ModalExternalLinksV2 from "common-components/modal-external-links-v2/ModalExternalLinksV2";
import { useModalHelperV2 } from "global/use-modal-helper-v2";

const namespace = "rts-pa-agenda-esg-modal-v2";

const EsgModalV2 = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.esgModal;
  const navigate = useNavigate();

  const mhResult = useModalHelperV2(
    pages.grid.esgModal.path,
    hasPermissions,
    "esg"
  );

  const { status, data, error } = useQuery<EsgItemV2>(
    ["agenda-item-cached", mhResult.agendaItemId],
    () => getAgendaItemCachedV2("esg", mhResult.agendaItemId),
    {
      enabled: mhResult.enabled
    }
  );

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className={namespace}
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequestV2 agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <OwnersContainerV2 owners={data?.owners} className="esg-modal" />
      <EnvironmentalSustainabilityMetricsV2
        data={data?.environmentalSustainability}
      />
      <ModalExternalLinksV2
        links={data?.links}
        className="esg-modal"
        buttonType="primary"
      />
    </PaModal>
  );
};

export default EsgModalV2;
