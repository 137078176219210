import { Array, Literal, Number, Static, String } from "runtypes";
import { AgendaItemSectionValidator } from "../agenda-item-common";
import { ExternalLinkValidator } from "api/common";

export const AgendaItemSectionEngagementScoreValidator =
  AgendaItemSectionValidator.extend({
    type: Literal("engagementScore"),
    date: String,
    productEngagementScore: Number,
    previousDate: String,
    productChange: Number,
    amgenAverage: Number,
    benchmark: Number,
    links: Array(ExternalLinkValidator)
  });

export type AgendaItemSectionEngagementScore = Static<
  typeof AgendaItemSectionEngagementScoreValidator
>;
