import { Literal, Record, Static, String, Union } from "runtypes";
import {
  PipelineEnrollmentStatusValidator,
  AdvancedTooltipValidator,
  PipelineActivityStatusValidator
} from "../common";

export const NextApprovalLaunchE2lValidator = Record({
  type: Union(
    Literal("e2lPortal"), // Next Priority E2L Portal (Pipeline)
    Literal("priorityApproval"), // Next Priority Approval (Commercial)
    Literal("priorityLaunch") // Next Priority Launch (Both Pipeline and Commercial)
  ),
  descriptor: String, // e2lIppShortName (pipeline) OR "{geographicArea} {milestoneShortName}" (commercial)
  date: String.nullable(), // e2lLauDate (pipeline) OR endDateCurrentApprovedCab (commercial)
  geographicArea: String.nullable(),
  status: PipelineActivityStatusValidator.nullable(),
  statusTooltip: AdvancedTooltipValidator.nullable()
});

export type NextApprovalLaunchE2l = Static<
  typeof NextApprovalLaunchE2lValidator
>;

export const NextPriorityMilestoneValidator = Record({
  description: String,
  studyShortDescription: String.nullable(),
  milestoneShortName: String,
  date: String,
  geographicArea: String.nullable(),
  status: PipelineActivityStatusValidator,
  statusTooltip: AdvancedTooltipValidator
});

export type NextPriorityMilestone = Static<
  typeof NextPriorityMilestoneValidator
>;

export const PipelineStudyEnrollmentValidator = Record({
  studyShortDescription: String,
  date: String,
  dateType: Union(Literal("fse"), Literal("lse")),
  enrStatus: PipelineEnrollmentStatusValidator,
  enrStatusTooltip: AdvancedTooltipValidator.nullable()
});

export type PipelineStudyEnrollment = Static<
  typeof PipelineStudyEnrollmentValidator
>;
