import axios from "axios";
import { Record, Number, Static } from "runtypes";
import { getAgendaId } from "utils/multitenancy";

const SubmissionCountValidator = Record({
  count: Number
});

export type SubmissionCount = Static<typeof SubmissionCountValidator>;

export const getSubmissionsCountV2 = async (
): Promise<SubmissionCount> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<SubmissionCount>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/submission/count`
  );

  return result.data;
};
