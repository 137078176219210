import axios from "axios";
import { Archetype } from "./common";
import { CommercialItem, CommercialItemValidator } from "./getCommercialItem";
import { PipelineItem, PipelineItemValidator } from "./getPipelineItem";
import { TalentDinb, TalentDinbValidator } from "./getTalentDinb";
import { EsgItem, EsgItemValidator } from "./esg";
import { BiosimilarItem, BiosimilarItemValidator } from "./biosimilar";

type GetAgendaItemCachedResult<T extends Archetype> = T extends "Commercial"
  ? CommercialItem
  : T extends "Pipeline"
  ? PipelineItem
  : T extends "TalentDinb"
  ? TalentDinb
  : T extends "ESG"
  ? EsgItem
  : T extends "Biosimilar"
  ? BiosimilarItem
  : never;

export const getAgendaItemCached = async <T extends Archetype>(
  archetype: T,
  id: number
): Promise<GetAgendaItemCachedResult<T>> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<GetAgendaItemCachedResult<T>>(
    `${REACT_APP_DOMAIN}/v2/agenda_items/cached_agenda_item?agenda_item_id=${id}`
  );

  // const result = {
  //   data: TestData as unknown as CommercialItem
  // };

  switch (archetype) {
    case "Commercial":
      CommercialItemValidator.check(result.data);
      break;
    case "Pipeline":
      PipelineItemValidator.check(result.data);
      break;
    case "TalentDinb":
      TalentDinbValidator.check(result.data);
      break;
    case "ESG":
      EsgItemValidator.check(result.data);
      break;
    case "Biosimilar":
      BiosimilarItemValidator.check(result.data);
      break;
  }

  return result.data;
};

export const putOfficersGridCache = async (): Promise<void> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  await axios.put(`${REACT_APP_DOMAIN}/api/v1/update_officers_grid_cache`);
};
