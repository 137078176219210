import React, { useMemo } from "react";
import "./AIModalSectionSalesDemand.scss";
import {
  AccordionWithStatus,
  Label,
  Text,
  getAdvancedTooltipContent
} from "common-components";
import {
  AgendaItemSectionSalesDemand,
  AgendaItemSectionSalesDemandTable
} from "api/agenda-item/sections";
import {
  ComposedLineChartComponent,
  LineType
} from "@gitlab-rtsensing/component-library";
import { colors, format } from "utils";

const namespace = "rts-pa-agenda-item-modal-section-sales-demand";

type Props = {
  section: AgendaItemSectionSalesDemand;
};

const TableSection = (props: AgendaItemSectionSalesDemandTable) => {
  return (
    <div className="table-section">
      <Text strong>{props.title}</Text>
      {props.rows.map((row, i) => (
        <div className="table-row" key={i}>
          {row.items.map((item, i) => (
            <div className="table-item" key={i}>
              <Label>{item.label}</Label>
              <Text type={item.status}>{item.value}</Text>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const AIModalSectionSalesDemand = (props: Props) => {
  const data = useMemo(() => {
    return props.section.chart.map(item => ({
      label: item.label || undefined,
      forecast: item.forecast || undefined,
      value: item.value || undefined
    }));
  }, [props.section]);

  const firstIndex = 0;
  const middleIndex = Math.ceil(data.length / 2) - 2;
  const lastIndex = data.length - 1;

  const customTicks = [
    data[firstIndex].label || "",
    data[middleIndex].label || "",
    data[lastIndex].label || ""
  ];

  return (
    <AccordionWithStatus
      title={props.section.title}
      status={props.section.status}
      className={namespace}
      externalLinks={props.section.links}
      tooltipContent={getAdvancedTooltipContent(props.section.tooltip)}
      accordionSyncKey="AgendaItemModal"
      accordionSyncId={props.section.id}
    >
      <div className={namespace}>
        {props.section.chartTitle && (
          <div className={`${namespace}-chart-title`}>
            {props.section.chartTitle}
          </div>
        )}
        <ComposedLineChartComponent
          data={data}
          xAxisKey="label"
          dataKeys={[
            { label: "value", dot: false, color: colors.primaryBlue },
            {
              label: "forecast",
              dot: false,
              color: colors.primaryBlue,
              type: LineType.DASH,
              strokeWidth: 2
            }
          ]}
          chartHeight={175}
          useTooltip
          yAxisWidth={43}
          tick
          ticks={customTicks}
          dataValueFormatter={(
            value: string | number | Array<string | number>
          ) => {
            if (typeof value === "number") {
              return format.simple(value, { padZeroIfDecimal: true });
            }

            return value.toString();
          }}
        />
        {props.section.tables.map((t, i) => (
          <TableSection key={i} title={t.title} rows={t.rows} />
        ))}
      </div>
    </AccordionWithStatus>
  );
};

export default AIModalSectionSalesDemand;
