import React from "react";
import "./ComModalBrandNbrxVolume.scss";
import {
  CommercialBrandNbrxVolumeSection,
  CommercialBrandInactiveSection,
  Status
} from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";

const namespace = "rts-pa-commercial-modal-brand-nbrx-volume";

type MetricProps = {
  metric: {
    label: string;
    currentVsPrevious: string;
    recentAvg: string;
    recentGoal: string;
  };
  status?: Status;
};

const BrandNbrxVolumeMetric = (props: MetricProps) => {
  return (
    <div className={`${namespace}-metric`}>
      <div className={`${namespace}-metric-label`}>{props.metric.label}</div>

      <div className={`${namespace}-metric-data`}>
        {props.metric.currentVsPrevious}
      </div>
      <div className={`${namespace}-metric-data ${props.status || ""}`}>
        {props.metric.recentAvg}
      </div>
      <div className={`${namespace}-metric-data`}>
        {props.metric.recentGoal}
      </div>
    </div>
  );
};

type Props = {
  data:
    | CommercialBrandNbrxVolumeSection
    | CommercialBrandInactiveSection
    | undefined
    | null;
};

const ComModalBrandNbrxVolume = (props: Props) => {
  const title = "US NBRx Volume by Specialties";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return (
      <AccordionWithStatus
        title={title}
        tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
        status="gray"
      />
    );
  }

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={title}
      className={namespace}
      externalLinks={props.data.externalLinks}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      accordionSyncKey="CommercialModal"
    >
      <div>
        <div className={`${namespace}-table-header`}>
          <div className={`${namespace}-table-header-space`} />
          <div className={`${namespace}-table-header-label`}>c4w vs p4w</div>
          <div className={`${namespace}-table-header-label`}>r4w avg</div>
          <div className={`${namespace}-table-header-label`}>r4w goal</div>
        </div>
        <div className={`${namespace}-table-subheader`}>cardiologists</div>
        <BrandNbrxVolumeMetric metric={props.data.cardiologists[0]} />
        <BrandNbrxVolumeMetric
          metric={props.data.cardiologists[1]}
          status={props.data.status}
        />
        <div className={`${namespace}-table-subheader`}>pcps</div>
        <BrandNbrxVolumeMetric metric={props.data.pcps[0]} />
        <BrandNbrxVolumeMetric metric={props.data.pcps[1]} />
      </div>
    </AccordionWithStatus>
  );
};

export default ComModalBrandNbrxVolume;
