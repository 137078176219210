import { BiosimilarsPipelineGroupValidatorV2 } from "./biosimilars-item-v2/biosimilars-pipeline-group-v2";
import {
  ArchetypeValidatorV2,
  LinkValidator,
  OwnersValidator,
  SummaryInfoValidator
} from "./common-v2";
import { Array, Number, Record, Static, String } from "runtypes";

export const BiosimilarsItemValidatorV2 = Record({
  id: Number,
  agendaItemVersionId: Number,
  archetype: ArchetypeValidatorV2,
  triggers: Array(String),
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  pipeline: BiosimilarsPipelineGroupValidatorV2,
  links: Array(LinkValidator)
});

export type BiosimilarsItemV2 = Static<typeof BiosimilarsItemValidatorV2>;
