import { Array, Literal, Record, Static, String } from "runtypes";
import { AgendaItemSectionValidator } from "../agenda-item-common";
import {
  ExternalLinkValidator,
  OwnersValidator,
  StatusValidator
} from "api/common";

const AgendaItemSectionLocationCategoryValidator = Record({
  status: StatusValidator,
  text: String.nullable()
});

export const AgendaItemSectionLocationValidator =
  AgendaItemSectionValidator.extend({
    type: Literal("location"),
    owners: OwnersValidator,
    scope: AgendaItemSectionLocationCategoryValidator,
    timeline: AgendaItemSectionLocationCategoryValidator,
    value: AgendaItemSectionLocationCategoryValidator,
    cost: AgendaItemSectionLocationCategoryValidator,
    risk: AgendaItemSectionLocationCategoryValidator,
    links: Array(ExternalLinkValidator)
  });

export type AgendaItemSectionLocation = Static<
  typeof AgendaItemSectionLocationValidator
>;
