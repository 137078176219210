import { useEffect } from "react";
import { useNavLinks } from "./use-nav-links";
import { NavLinkSections, NavManager, NavSection } from "@amgen/rtsensing-nav";
import { Permissions } from "global/permissions";

//one navManager instance per site
let navManager: NavManager | undefined = undefined;
if (NavManager) {
  navManager = new NavManager("Prioritized Agenda");
}

function pruneNavSections(
  navSections: NavSection[] | undefined
): NavSection[] | undefined {
  if (!navSections) {
    return undefined;
  }

  const newNavSections: NavSection[] = [];

  for (const navSection of navSections) {
    const newNavSectionLinks = navSection.links.filter(ns => !ns.disabled);

    if (newNavSectionLinks.length) {
      newNavSections.push({
        sectionLabel: navSection.sectionLabel,
        links: newNavSectionLinks
      });
    }
  }

  if (newNavSections.length) {
    return newNavSections;
  }

  return undefined;
}

export function useNavManager(permissions: Permissions) {
  const { status, links } = useNavLinks(permissions);

  useEffect(() => {
    if (!navManager || status !== "success") {
      return;
    }

    const navLinkSections: NavLinkSections = {
      internalSections: [
        {
          sectionLabel: "Options",
          links: [links.globalConfig, links.updateHomepageImage]
        }
      ],
      externalSections: [
        {
          sectionLabel: "Related Links",
          links: [links.functionalPa, links.ceoStaff, links.operatingTeam]
        }
      ]
    };

    navLinkSections.internalSections = pruneNavSections(
      navLinkSections.internalSections
    );
    navLinkSections.externalSections = pruneNavSections(
      navLinkSections.externalSections
    );

    navManager.setNavLinkSections(navLinkSections);
  }, [permissions, status, links]);
}
