import { Number, Record, Static, Array } from "runtypes";
import {
  ExternalLinkValidator,
  OwnersValidator,
  SummaryInfoValidator
} from "./common";
import { TalentDinbTalentSectionValidator } from "./talent-dinb/talent-dinb-talent";
import { TalentDinbDiversityInclusionBelongingSectionValidator } from "./talent-dinb/talent-dinb-diversity-inclusion-belonging";
import { TalentDinbLaborProductivitySectionValidator } from "./talent-dinb/talent-dinb-labor-productivity";

export const TalentDinbValidator = Record({
  agendaItemId: Number,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator.nullable(),
  talent: TalentDinbTalentSectionValidator.nullable(),
  diversityInclusionBelonging:
    TalentDinbDiversityInclusionBelongingSectionValidator.nullable(),
  laborProductivity: TalentDinbLaborProductivitySectionValidator.nullable(),
  externalLinks: Array(ExternalLinkValidator)
});

export type TalentDinb = Static<typeof TalentDinbValidator>;
