import React from "react";
import "./LimitedViewManagementModal.scss";
import { useMutation } from "@tanstack/react-query";
import { useStatusManager } from "global/use-status-manager";
import { queryClient } from "global/query-config";
import {
  Button,
  InfoIcon
} from "@opsdti-global-component-library/amgen-design-system";
import { Text, PaModal } from "common-components";
import { putOfficersGridCacheV2 } from "api/cache-v2";

const namespace = "rts-pa-agenda-grid-limited-view-management-modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function LimitedViewManagementModalV2(
  props: Props
): JSX.Element {
  const { status: updateStatus, mutate: updateOfficersCache } = useMutation(
    putOfficersGridCacheV2,
    {
      onMutate: () => {
        overrideStatus("updateOfficersCache", "loading");
      },
      onSuccess: () => {
        queryClient
          .invalidateQueries({
            queryKey: ["agenda-items", true],
            refetchType: "all"
          })
          .then(() => {
            props.onClose();
            setTimeout(() => {
              //keep spinner going when closing modal
              overrideStatus("updateOfficersCache", undefined);
            }, 500);
          });
      },
      onError: () => {
        overrideStatus("updateOfficersCache", undefined);
      }
    }
  );

  const { status, overrideStatus } = useStatusManager(updateStatus);

  return (
    <PaModal
      title="Publish to Limited View"
      status={status}
      isOpen={props.isOpen}
      onClose={props.onClose}
      className={`${namespace}`}
      footer={
        <div className={`${namespace}-footer`}>
          <Button
            text="Cancel"
            type="secondary"
            onClick={props.onClose}
            disabled={status === "loading"}
          />
          <Button
            text="Publish"
            type="primary"
            onClick={() => updateOfficersCache()}
            disabled={status === "loading"}
          />
        </div>
      }
    >
      <div className={`${namespace}-body`}>
        <InfoIcon height={16} width={16} />
        <Text>
          You are about to publish the current live agenda to the limited view.{" "}
          <Text italic>This cannot be undone</Text>
        </Text>
      </div>
    </PaModal>
  );
}
