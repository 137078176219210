import React from "react";
import { AdvancedTooltip, StatusWithAccelerated } from "api/common";
import "./BiosimilarsProjectMetricV2.scss";
import StatusIcon from "common-components/status-icon/StatusIcon";
import Tooltip from "common-components/tooltip/Tooltip";
import AdvancedTooltipContent from "common-components/advanced-tooltip/AdvancedTooltipContent";
import { LocationIcon } from "@opsdti-global-component-library/amgen-design-system";
import { Text } from "common-components";

type MetricProps = {
  id: string;
  name: string;
  status: StatusWithAccelerated;
  statusTooltip: AdvancedTooltip;
  readinessDate: string;
  region: string;
};

const namespace =
  "rts-pa-biosimilars-modal-planned-market-readiness-project-v2";

const BiosimilarsProjectMetricV2 = (props: MetricProps) => {
  return (
    <div className={`${namespace}-container`}>
      <div className={`${namespace}-left-column`}>
        <div className={`${namespace}-id-row`}>
          <Text strong>{props.id}</Text>
        </div>
        <div className={`${namespace}-name-row`}>
          <Text>{props.name}</Text>
        </div>
      </div>
      <div className={`${namespace}-right-column`}>
        <div className={`${namespace}-market-readiness-row-v2`}>
          <div className={`${namespace}-market-readiness-row-v2-status-column`}>
            <span>
              <Tooltip tooltipIcon={<StatusIcon status={props.status} />}>
                <AdvancedTooltipContent tooltip={props.statusTooltip} />
              </Tooltip>
            </span>
            <Text>{props.readinessDate}</Text>
          </div>
          <div className={`${namespace}-market-readiness-row-v2-region-column`}>
            <span>
              <LocationIcon width={16} height={16} />
            </span>
            <Text>{props.region}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiosimilarsProjectMetricV2;
