import React from "react";
import "./ComModalSupplyLocation.scss";
import { CommercialSupplyLocationSection } from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import OwnersContainer from "common-components/owners-container/OwnersContainer";
import SupplyLocationMetrics from "./common-suppy-location-metrics/SupplyLocationMetrics";
import { InactiveSection } from "api/common";

type Props = {
  data: CommercialSupplyLocationSection | InactiveSection | null;
};

const ComModalSupplyLocation = (props: Props) => {
  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return <AccordionWithStatus title={props.data.title} status="gray" />;
  }

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={props.data.title}
      className={`rts-pa-commercial-modal-supply-location ${props.data.title}`}
      externalLinks={props.data.externalLinks}
      accordionSyncKey="CommercialModal"
    >
      <div className="rts-pa-commercial-modal-supply-location">
        <OwnersContainer owners={props.data.owners} />
        <div className="rts-pa-commercial-modal-supply-metrics">
          <SupplyLocationMetrics title="scope" data={props.data.scope} />
          <SupplyLocationMetrics title="timeline" data={props.data.timeline} />
          <SupplyLocationMetrics title="value" data={props.data.value} />
          <SupplyLocationMetrics title="cost" data={props.data.cost} />
        </div>
        <SupplyLocationMetrics title="risk" data={props.data.risk} />
      </div>
    </AccordionWithStatus>
  );
};

export default ComModalSupplyLocation;
