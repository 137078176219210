import axios, { AxiosResponse } from "axios";
import { Boolean, Record, Static } from "runtypes";
import { getAgendaId } from "utils/multitenancy";

const PaPermissionsValidator = Record({
  superAdmin: Boolean,
  gridAdmin: Boolean,
  gridStatus: Boolean,
  gridReader: Boolean,
  createAgenda: Boolean,

  commercial: Boolean,
  pipeline: Boolean,
  nonProduct: Boolean,

  export: Boolean,
  changeLog: Boolean,
  activityLog: Boolean,

  homePdf: Boolean,
  ceoStaffNav: Boolean,
  funcNav: Boolean
});

const WorkspacePermissionsValidator = Record({
  pa: Boolean,
  brand: Boolean,
  pipeline: Boolean,
  supply: Boolean,
  people: Boolean,
  finance: Boolean
});

const AuthorizationValidator = Record({
  ws: WorkspacePermissionsValidator,
  pa: PaPermissionsValidator
});

export type Authorization = Static<typeof AuthorizationValidator>;

export const getAuthorization = async (): Promise<Authorization> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result: AxiosResponse<Authorization> = await axios.get<Authorization>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/authorization`
  );

  AuthorizationValidator.check(result.data);

  return result.data;
};
