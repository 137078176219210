import PaModal from "common-components/pa-modal/PaModal";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ImageUploader } from "./image-uploader/ImageUploader";
import { PermissionsContext } from "global/permissions";
import { useModalHelperV2 } from "global/use-modal-helper-v2";
import pages from "pages/pages";
import { uploadTileImage } from "api/updateHomepageImage";
import { useStatusManager } from "global/use-status-manager";
import { useMutation } from "@tanstack/react-query";

const namespace = "rts-pa-update-homepage-image-modal";

export const UpdateHomepageImageModal = () => {
  const hasPermissions =
    useContext(PermissionsContext).leftNavLinks.updateHomepageImage;
  const mhResult = useModalHelperV2(
    pages.grid.updateHomepageImageModal.path,
    hasPermissions
  );

  const { status: uploadImageStatus, overrideStatus } = useStatusManager();

  const navigate = useNavigate();

  const { mutate: uploadImage } = useMutation(uploadTileImage, {
    onMutate: () => {
      overrideStatus("uploadImage", "loading");
    },
    onSuccess: () => {
      navigate(pages.grid.go());
      setTimeout(() => overrideStatus("uploadImage", "success"), 500);
    }
  });

  return (
    <PaModal
      onClose={() => navigate(pages.grid.go())}
      className={namespace}
      title="Update Homepage Image"
      status={uploadImageStatus}
      isOpen={mhResult.enabled}
    >
      <ImageUploader uploadImage={uploadImage} />
    </PaModal>
  );
};
