import { Number, Record, Static, String, Array } from "runtypes";
import {
  ExternalLinkValidator,
  OwnersValidator,
  SummaryInfoValidator
} from "./common";
import { CommercialGlobalNetSalesSectionValidator } from "./commercial-item/commercial-global-net-sales";
import { CommercialBrandSectionValidator } from "./commercial-item/commercial-brand";
import { CommercialPipelineSectionValidator } from "./commercial-item/commercial-pipeline";
import { CommercialSupplySectionValidator } from "./commercial-item/commercial-supply";
import { CommercialPeopleSectionValidator } from "./commercial-item/commercial-people";

export const CommercialItemValidator = Record({
  title: String,
  agendaItemId: Number,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator.nullable(),
  globalNetSales: CommercialGlobalNetSalesSectionValidator.nullable(),
  brand: CommercialBrandSectionValidator.nullable(),
  pipeline: CommercialPipelineSectionValidator.nullable(),
  supply: CommercialSupplySectionValidator.nullable(),
  people: CommercialPeopleSectionValidator.nullable(),
  externalLinks: Array(ExternalLinkValidator)
});

export type CommercialItem = Static<typeof CommercialItemValidator>;
