import React from "react";
import "./KeyEventsCalendarRowMenu.scss";
import { Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
  ArrowRightIcon,
  EditIcon
} from "@opsdti-global-component-library/amgen-design-system";
import KeyEventsCalendarRowMenuItem from "./item/KeyEventsCalendarRowMenuItem";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";

export const namespace = "rts-pa-key-events-calendar-row-menu";

type Props = {
  rowId: number;
  pipelineUrl: string;
};

export default function KeyEventsCalendarRowMenu(props: Props): JSX.Element {
  const navigate = useNavigate();

  const content = (
    <div className={`${namespace}-content`}>
      <KeyEventsCalendarRowMenuItem
        icon={<ArrowRightIcon />}
        label="Go to Pipeline Page"
        onClick={() => {
          window.open(props.pipelineUrl, "_blank");
        }}
      />
      <KeyEventsCalendarRowMenuItem
        icon={<EditIcon height={14} />}
        label="Edit Row"
        onClick={() => {
          navigate(pages.keyEvents.editItemModal.go(props.rowId));
        }}
      />
    </div>
  );

  return (
    <Popover className={namespace} content={content} trigger="click">
      <MoreOutlined />
    </Popover>
  );
}
