import {
  Array,
  Boolean,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union
} from "runtypes";

export const CalendarBoardRowMilestoneValidator = Record({
  id: Number,
  milestoneVersionId: Number,
  name: String, // title
  text: String, // additional info
  type: Union(Literal("manual"), Literal("automated")),
  milestoneLinkId: String.nullable(),
  milestoneCalendarKeyId: Number,
  startDate: String,
  endDate: String.nullable(),
  isVisible: Boolean.nullable(),
  isVisibleToOfficers: Boolean
});

export type CalendarBoardRowMilestone = Static<
  typeof CalendarBoardRowMilestoneValidator
>;

export const CalendarBoardRowItemValidator = Record({
  id: Number,
  calendarRowVersionId: Number,
  calendarRowLinkId: String.nullable(),
  order: Number,
  name: String,
  isVisibleToOfficers: Boolean,
  linkToSource: String,
  milestones: Array(CalendarBoardRowMilestoneValidator)
});

export type CalendarBoardRowItem = Static<typeof CalendarBoardRowItemValidator>;

export const CalendarBoardValidator = Record({
  rows: Array(CalendarBoardRowItemValidator)
});

export type CalendarBoard = Static<typeof CalendarBoardValidator>;
