import {
  AdvancedTooltipValidator,
  ExternalLinkValidator,
  StatusValidator
} from "api/common";
import { Array, Number, Record, Static } from "runtypes";
import { SupplyKeyInsightsValidator } from "./esg-common";

export const TotalCarbonWasteWaterReductionOffsetValidator = Record({
  tooltip: AdvancedTooltipValidator.nullable(),
  status: StatusValidator,
  supplyKeyInsights: SupplyKeyInsightsValidator,
  carbon: Number,
  waste: Number,
  water: Number,
  externalLinks: Array(ExternalLinkValidator)
});

export type TotalCarbonWasteWaterReductionOffset = Static<
  typeof TotalCarbonWasteWaterReductionOffsetValidator
>;
