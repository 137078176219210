import axios from "axios";
import { Array, Number, Record, Static } from "runtypes";
import { AgendaItemValidatorV2 } from "./common-v2";
import { getAgendaId } from "utils/multitenancy";

const AgendaItemsGroupValidatorV2 = Record({
  value: Number,
  focus: Number,
  items: Array(AgendaItemValidatorV2)
});

export type AgendaItemsGroupV2 = Static<typeof AgendaItemsGroupValidatorV2>;

const AgendaGridGroupsValidatorV2 = Record({
  groups: Array(AgendaItemsGroupValidatorV2)
});

export type AgendaGridGroupsV2 = Static<typeof AgendaGridGroupsValidatorV2>;

export const getAgendaItemsV2 = async (
  limitedView: boolean
): Promise<AgendaGridGroupsV2> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<AgendaGridGroupsV2>(
    `${REACT_APP_DOMAIN}/api/v4/${getAgendaId()}/agenda_item/board`,
    {
      params: {
        limited_view: limitedView
      }
    }
  );

  AgendaGridGroupsValidatorV2.check(result.data);

  return result.data;
};

export const putAgendaItemsV2 = async (
  groups: AgendaGridGroupsV2
): Promise<AgendaGridGroupsV2> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<AgendaGridGroupsV2>(
    `${REACT_APP_DOMAIN}/api/v4/${getAgendaId()}/agenda_item/board`,
    groups
  );

  AgendaGridGroupsValidatorV2.check(result.data);

  return result.data;
};
