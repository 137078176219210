import { ExternalLinkValidator, StatusValidator } from "api/common";
import { Array, Number, Record, Static } from "runtypes";

const TalentDinbDiversityInclusionBelongingWomenInLeadershipValidator = Record({
  status: StatusValidator,
  executive: Number,
  management: Number,
  externalLinks: Array(ExternalLinkValidator)
});

export type TalentDinbDiversityInclusionBelongingWomenInLeadership = Static<
  typeof TalentDinbDiversityInclusionBelongingWomenInLeadershipValidator
>;

export const TalentDinbDiversityInclusionBelongingSectionValidator = Record({
  womenInLeadership:
    TalentDinbDiversityInclusionBelongingWomenInLeadershipValidator
});

export type TalentDinbDiversityInclusionBelongingSection = Static<
  typeof TalentDinbDiversityInclusionBelongingSectionValidator
>;
