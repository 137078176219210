import React, { useCallback, useEffect, useMemo } from "react";
import "./CheckboxSimple.scss";
import classNames from "classnames";
import { FieldValUtils, ValidationError } from "global/use-validator";
import {
  CheckboxChangeEvent,
  Checkbox as DSCheckbox
} from "@opsdti-global-component-library/amgen-design-system";
import { v4 as uuid } from "uuid";

const namespace = "rts-pa-checkbox-simple";

type Props = {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  errorLabel?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  validator?: FieldValUtils;
};

export const CheckboxSimple = (props: Props) => {
  const id = useMemo(() => `${namespace}-${uuid()}`, []);

  const validate = useCallback(
    (checked: boolean) => {
      if (!props.validator) {
        return;
      }

      const errors: ValidationError[] = [];

      if (props.required && !checked) {
        const fieldName = props.errorLabel || props.label;

        errors.push({
          fieldError: `${fieldName || "This field"} is required.`,
          fullError: `${fieldName} is required.`
        });
      }

      props.validator.setErrors(id, errors);
    },
    [props.required, props.validator] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!props.validator) {
      return;
    }

    const unregister = props.validator.registerValidation(id, () =>
      validate(props.checked)
    );

    return () => {
      unregister();
    };
  }, [props.checked, validate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeWrapper = (e: CheckboxChangeEvent) => {
    const val = e.target.checked;
    props.onChange(val);
    validate(val);
  };

  const errors = props.validator?.errors.get(id) || [];
  const className = classNames(namespace, props.className, {
    error: !!errors.length
  });

  return (
    <div className={className}>
      <DSCheckbox checked={props.checked} onChange={onChangeWrapper}>
        {props.label}
        {props.required && <span className="required-asterisk"> *</span>}
      </DSCheckbox>
      {!!errors.length && (
        <div className={`${namespace}-errors-container`}>
          {errors.map((e, i) => (
            <div key={i} className="error-item">
              {e.fieldError}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckboxSimple;
