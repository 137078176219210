import React from "react";
import "./TalentDinbModalLaborProductivityMetricsV2.scss";
import ModalSection from "common-components/modal-section/ModalSection";
import TalentDinbModalLaborProductivityRevenuePerLnbV2 from "./revenue-per-lnb-v2/TalentDinbModalLaborProductivityRevenuePerLnbV2";
import { TalentDinbLaborProductivitySectionV2 } from "api/talent-dinb-v2/talent-dinb-labor-productivity-v2";

const namespace = "rts-pa-talent-dinb-modal-labor-productivity-metrics-v2";

type Props = {
  data: TalentDinbLaborProductivitySectionV2 | undefined | null;
};

const TalentDinbModalLaborProductivityMetricsV2 = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection title="Labor Productivity Metrics" className={namespace}>
      <div className={`${namespace}-container`}>
        <TalentDinbModalLaborProductivityRevenuePerLnbV2
          data={props.data.revenuePerLnb}
        />
      </div>
    </ModalSection>
  );
};

export default TalentDinbModalLaborProductivityMetricsV2;
