import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import "./TalentDinbModalV2.scss";
import PaModal from "common-components/pa-modal/PaModal";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { useModalHelperV2 } from "global/use-modal-helper-v2";
import { TalentDinbV2 } from "api/getTalentDinbV2";
import { getAgendaItemCachedV2 } from "api/cache-v2";
import ModalExternalLinksV2 from "common-components/modal-external-links-v2/ModalExternalLinksV2";
import OwnersContainerV2 from "common-components/owners-container-v2/OwnersContainerV2";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import SubmissionRequestV2 from "common-components/submission-request-v2/SubmissionRequestV2";
import TalentDinbModalTalentMetricsV2 from "./talent-v2/TalentDinbModalTalentMetricsV2";
import TalentDinbModalDinbMetricsV2 from "./dinb-v2/TalentDinbModalDinbMetricsV2";
import TalentDinbModalLaborProductivityMetricsV2 from "./labor-productivity-v2/TalentDinbModalLaborProductivityMetricsV2";

const TalentDinbModalV2 = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.talentDinbModal;
  const navigate = useNavigate();
  const mhResult = useModalHelperV2(
    pages.grid.talentDinbModal.path,
    hasPermissions,
    "talent_dinb"
  );

  const { status, data, error } = useQuery<TalentDinbV2>(
    ["agenda-item-cached", mhResult.agendaItemId],
    () => getAgendaItemCachedV2("talent_dinb", mhResult.agendaItemId),
    {
      enabled: mhResult.enabled
    }
  );

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className="rts-pa-talent-dinb-modal-v2"
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequestV2 agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <OwnersContainerV2 owners={data?.owners} className="talent-dinb-modal-v2" />
      <TalentDinbModalTalentMetricsV2 data={data?.talent} />
      <TalentDinbModalDinbMetricsV2 data={data?.diversityInclusionBelonging} />
      <TalentDinbModalLaborProductivityMetricsV2 data={data?.laborProductivity} />
      <ModalExternalLinksV2
        links={data?.links}
        className="talent-dinb-modal-v2"
        buttonType="primary"
      />
    </PaModal>
  );
};

export default TalentDinbModalV2;
