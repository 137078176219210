import axios from "axios";
import { Record, String, Static } from "runtypes";

const TileConfigValidator = Record({
  key: String,
  imageUrl: String
});

export type TileConfig = Static<typeof TileConfigValidator>;

export const uploadTileImage = async (image: File) => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;
  const formData = new FormData();

  formData.append("file", image);

  const result = await axios.post(
    `${REACT_APP_DOMAIN}/api/v1/home_tile_image`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );

  return result;
};

export const getTileImage = async (): Promise<TileConfig> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;
  const response = await axios.get(
    `${REACT_APP_DOMAIN}/api/v1/home_tile_image`
  );
  return response.data;
};
