import React, { useContext } from "react";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./AgendaGridPageHeader.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useGridModeHelper } from "global/use-grid-mode-helper";
import {
  CloseIcon,
  EditIcon,
  Button
} from "@opsdti-global-component-library/amgen-design-system";
import { SettingOutlined } from "@ant-design/icons";
import AgendaTabNavigation from "components/agenda-tab-navigation/AgendaTabNavigation";
import pages from "pages/pages";
import { CheckoutButtonWithTooltipV2 } from "common-components";
import { curationUrlsMap } from "components/submissions-table/SubmissionsTable";
import AgendaGridPublish from "components/agenda-grid-publish/AgendaGridPublish";
import LimitedViewManagement from "./limited-view-management/LimitedViewManagement";
import AgendaSelector from "components/agenda-selector/AgendaSelector";
import { useConflictManagement } from "global/use-conflict-management";

const namespace = "rts-pa-agenda-grid-page-header";

type Props = {
  onReorderSave: () => void;
  onExportClick: () => void;
};

export default function AgendaGridPageHeader(props: Props): JSX.Element {
  const perms = useContext(PermissionsContext);
  const navigate = useNavigate();
  const modeHelper = useGridModeHelper();
  const {
    isCheckOutInLoading,
    setIsCheckOutInLoading,
    refreshCheckoutStatus,
    checkout,
    checkin
  } = useConflictManagement();

  const renderCheckoutButton = () => {
    const handleCheckoutClick = async () => {
      setIsCheckOutInLoading(true);

      const result = await checkout();

      if (result.isSuccess) {
        setIsCheckOutInLoading(false);
        navigate(pages.grid.edit.go());
      } else {
        await refreshCheckoutStatus();
        setIsCheckOutInLoading(false);
      }
    };

    return (
      <CheckoutButtonWithTooltipV2
        text="Edit Agenda"
        type="primary"
        icon={<EditIcon width={14} height={14} />}
        disabled={isCheckOutInLoading}
        onClick={handleCheckoutClick}
      />
    );
  };
  const location = useLocation();

  const onSettingsClicked = () => {
    navigate(pages.grid.editAgendaModal.go(), {
      state: { prevUrl: location.pathname }
    });
  };

  return (
    <div className={namespace}>
      <div className={`${namespace}-left-container`}>
        <div className={`${namespace}-left-container-first-row`}>
          <AgendaSelector />
          <AgendaGridPublish />
          <PermissionsWrapper permissions={perms.grid.edit}>
            <SettingOutlined
              className={`${namespace}-settings-button`}
              onClick={onSettingsClicked}
            />
          </PermissionsWrapper>
        </div>
        <AgendaTabNavigation />
      </div>
      <div className={`${namespace}-right-container`}>
        <PermissionsWrapper permissions={modeHelper.isViewMode}>
          <div className="row-1">
            <PermissionsWrapper permissions={perms.grid.edit}>
              <LimitedViewManagement />
              {renderCheckoutButton()}
            </PermissionsWrapper>
          </div>
          <div className="row-2">
            <PermissionsWrapper permissions={perms.submissions}>
              <Button
                text="Manage"
                type="secondary"
                onClick={() => {
                  const { NODE_ENV } = window.__RUNTIME_CONFIG__;
                  const curationUrl = curationUrlsMap.get(NODE_ENV) || "";

                  window.open(
                    `${curationUrl}/user-assignments/1/request`, //workspace 1 is PA
                    "_blank"
                  );
                }}
              />
            </PermissionsWrapper>
            <PermissionsWrapper permissions={perms.grid.export}>
              <Button
                text="Export Current Agenda"
                type="secondary"
                className="print-button"
                onClick={props.onExportClick}
              />
            </PermissionsWrapper>
          </div>
        </PermissionsWrapper>
        <PermissionsWrapper
          permissions={perms.grid.edit && modeHelper.isEditMode}
        >
          <div className="row-1">
            <Button
              text="close"
              type="primary"
              disabled={isCheckOutInLoading}
              onClick={() => {
                setIsCheckOutInLoading(true);

                (async () => {
                  const result = await checkin();

                  if (result.isSuccess) {
                    setIsCheckOutInLoading(false);
                    navigate(pages.grid.go());
                  }
                })();
              }}
            />
          </div>
          <div className="row-2">
            <Button
              text="Rearrange"
              type="secondary"
              onClick={() => {
                navigate(pages.grid.edit.rearrange.go());
              }}
            />
          </div>
        </PermissionsWrapper>
        <PermissionsWrapper
          permissions={perms.grid.edit && modeHelper.isRearrangeMode}
        >
          <div className="row-1"></div>
          <div className="row-2">
            <Button
              text="Cancel Changes"
              type="primary"
              icon={<CloseIcon width={16} height={16} />}
              onClick={() => {
                navigate(pages.grid.edit.go());
              }}
            />
            <Button text="Save" type="primary" onClick={props.onReorderSave} />
          </div>
        </PermissionsWrapper>
      </div>
    </div>
  );
}
