import React from "react";
import "./Tooltip.scss";
import {
  InfoIcon,
  Popover
} from "@opsdti-global-component-library/amgen-design-system";

type Props = {
  tooltipIcon?: JSX.Element;
  children: JSX.Element | null | undefined;
};

export const Tooltip = (props: Props) => {
  if (!props.children) {
    return <></>;
  }

  return (
    <Popover content={props.children}>
      {props.tooltipIcon || (
        <InfoIcon color="secondary" width={16} height={16} />
      )}
    </Popover>
  );
};

export default Tooltip;
