import React from "react";
import "./ComModalBrandNbrxFulfillment.scss";
import {
  CommercialBrandNbrxFulfillmentSection,
  CommercialBrandInactiveSection
} from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { Status } from "api/common";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";

const namespace = "rts-pa-commercial-modal-brand-nbrx-fulfillment";

type MetricProps = {
  metric: {
    label: string;
    previous: string;
    current: string;
    goal: string | null;
  };
  status?: Status;
};

const BrandNbrxFulfillmentMetric = (props: MetricProps) => {
  return (
    <div className={`${namespace}-metric`}>
      <div className={`${namespace}-metric-label`}>{props.metric.label}</div>
      <div className={`${namespace}-metric-data`}>{props.metric.previous}</div>
      <div className={`${namespace}-metric-data ${props.status || ""}`}>
        {props.metric.current}
      </div>
      <div className={`${namespace}-metric-data`}>
        {props.metric.goal ?? "-"}
      </div>
    </div>
  );
};

type Props = {
  data:
    | CommercialBrandNbrxFulfillmentSection
    | CommercialBrandInactiveSection
    | undefined
    | null;
};

const ComModalBrandNbrxFulfillment = (props: Props) => {
  const title = "US NBRx Fulfillment";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return (
      <AccordionWithStatus
        title={title}
        tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
        status="gray"
      />
    );
  }

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={title}
      className={namespace}
      externalLinks={props.data.externalLinks}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      accordionSyncKey="CommercialModal"
    >
      <div className={`${namespace}-table-header`}>
        <div className={`${namespace}-table-header-space`} />
        <div className={`${namespace}-table-header-label`}>
          r4w Previous year
        </div>
        <div className={`${namespace}-table-header-label`}>
          r4w current year
        </div>
        <div className={`${namespace}-table-header-label`}>r4w goal</div>
      </div>
      <BrandNbrxFulfillmentMetric
        metric={props.data.data[0]}
        status={props.data.status}
      />
      <BrandNbrxFulfillmentMetric metric={props.data.data[1]} />
      <BrandNbrxFulfillmentMetric metric={props.data.data[2]} />
      <BrandNbrxFulfillmentMetric metric={props.data.data[3]} />
    </AccordionWithStatus>
  );
};

export default ComModalBrandNbrxFulfillment;
