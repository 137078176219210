import React from "react";
import { AdvancedTooltip as AdvancedTooltipType } from "api";
import AdvancedTooltipContent from "./AdvancedTooltipContent";
import { Tooltip } from "common-components/tooltip/Tooltip";

type Props = {
  className?: string;
  tooltipIcon?: JSX.Element;
  tooltip: AdvancedTooltipType | null;
};
export const AdvancedTooltip = (props: Props) => {
  if (props.tooltip === null) {
    return <></>;
  }

  return (
    <Tooltip tooltipIcon={props.tooltipIcon}>
      <AdvancedTooltipContent
        className={props.className}
        tooltip={props.tooltip}
      />
    </Tooltip>
  );
};
