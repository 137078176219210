import { Array, Number, Record, Static, String } from "runtypes";
import {
  StatusValidator,
  InactiveSectionValidator,
  ExternalLinkValidator,
  AdvancedTooltipValidator
} from "../common";
import { CommercialCommonSalesDemandLiteSectionValidator } from "./commercial-common";

const CommercialBrandInactiveSectionValidator = InactiveSectionValidator.And(
  Record({
    tooltip: AdvancedTooltipValidator.nullable().optional()
  })
);

const CommercialBrandSalesDemandSectionTableRowItemValidator = Record({
  label: String,
  value: String,
  status: StatusValidator.nullable()
});

const CommercialBrandSalesDemandSectionTableRowValidator = Record({
  items: Array(CommercialBrandSalesDemandSectionTableRowItemValidator)
});

const CommercialBrandSalesDemandSectionTableValidator = Record({
  title: String,
  rows: Array(CommercialBrandSalesDemandSectionTableRowValidator)
});

const BrandSalesDemandSectionValidator = Record({
  title: String,
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable(),
  chartTitle: String.nullable(),
  chart: Array(
    Record({
      label: String,
      value: Number.nullable(),
      forecast: Number.nullable()
    })
  ),
  tables: Array(CommercialBrandSalesDemandSectionTableValidator),
  externalLinks: Array(ExternalLinkValidator)
});

const CommercialBrandIdnAccountWinsValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable()
});

const CommercialBrandNbrxFulfillmentValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable(),
  data: Array(
    Record({
      label: String,
      previous: String,
      current: String,
      goal: String.nullable()
    })
  ),
  externalLinks: Array(ExternalLinkValidator)
});

const CommercialBrandNbrxVolumeSectionValidator = Record({
  label: String,
  currentVsPrevious: String,
  recentAvg: String,
  recentGoal: String
});

const CommercialBrandNbrxVolumeValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable(),
  cardiologists: Array(CommercialBrandNbrxVolumeSectionValidator),
  pcps: Array(CommercialBrandNbrxVolumeSectionValidator),
  externalLinks: Array(ExternalLinkValidator)
});

const CommercialBrandPsoBioNaiveShareValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable().optional()
});

const CommercialBrandSpecPharmNbrxCommFulfillRateValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable().optional()
});

const CommercialBrandCopayEnrollmentRateValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable().optional()
});

const CommercialBrandTargetsAtFrequencyValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable().optional()
});

const CommercialBrandFrequencyValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable().optional()
});

const CommercialBrandShareOfVoiceValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable().optional()
});

export const CommercialBrandSectionValidator = Record({
  salesDemandLiteSections: Array(
    CommercialCommonSalesDemandLiteSectionValidator.Or(
      CommercialBrandInactiveSectionValidator
    )
  ),
  salesDemandSections: Array(
    BrandSalesDemandSectionValidator.Or(CommercialBrandInactiveSectionValidator)
  ),
  idnAccountWins: CommercialBrandIdnAccountWinsValidator.Or(
    CommercialBrandInactiveSectionValidator
  ).nullable(),
  nbrxFulfillment: CommercialBrandNbrxFulfillmentValidator.Or(
    CommercialBrandInactiveSectionValidator
  ).nullable(),
  nbrxVolume: CommercialBrandNbrxVolumeValidator.Or(
    CommercialBrandInactiveSectionValidator
  ).nullable(),
  psoBioNaiveShare: CommercialBrandPsoBioNaiveShareValidator.Or(
    CommercialBrandInactiveSectionValidator
  ).nullable(),
  specPharmNbrxCommFulfillRate:
    CommercialBrandSpecPharmNbrxCommFulfillRateValidator.Or(
      CommercialBrandInactiveSectionValidator
    ).nullable(),
  copayEnrollmentRate: CommercialBrandCopayEnrollmentRateValidator.Or(
    CommercialBrandInactiveSectionValidator
  ).nullable(),
  targetsAtFrequency: CommercialBrandTargetsAtFrequencyValidator.Or(
    CommercialBrandInactiveSectionValidator
  ).nullable(),
  frequency: CommercialBrandFrequencyValidator.Or(
    CommercialBrandInactiveSectionValidator
  ).nullable(),
  shareOfVoice: CommercialBrandShareOfVoiceValidator.Or(
    CommercialBrandInactiveSectionValidator
  ).nullable()
});

export type CommercialBrandInactiveSection = Static<
  typeof CommercialBrandInactiveSectionValidator
>;
export type CommercialBrandSalesDemandSectionTable = Static<
  typeof CommercialBrandSalesDemandSectionTableValidator
>;
export type CommercialBrandSalesDemandSection = Static<
  typeof BrandSalesDemandSectionValidator
>;
export type CommercialBrandIdnAccountWinsSection = Static<
  typeof CommercialBrandIdnAccountWinsValidator
>;
export type CommercialBrandNbrxFulfillmentSection = Static<
  typeof CommercialBrandNbrxFulfillmentValidator
>;
export type CommercialBrandNbrxVolumeSection = Static<
  typeof CommercialBrandNbrxVolumeValidator
>;
export type CommercialBrandPsoBioNaiveShare = Static<
  typeof CommercialBrandPsoBioNaiveShareValidator
>;
export type CommercialBrandSpecPharmNbrxCommFulfillRate = Static<
  typeof CommercialBrandSpecPharmNbrxCommFulfillRateValidator
>;
export type CommercialBrandCopayEnrollmentRate = Static<
  typeof CommercialBrandCopayEnrollmentRateValidator
>;
export type CommercialBrandTargetsAtFrequency = Static<
  typeof CommercialBrandTargetsAtFrequencyValidator
>;
export type CommercialBrandFrequency = Static<
  typeof CommercialBrandFrequencyValidator
>;
export type CommercialBrandShareOfVoiceSection = Static<
  typeof CommercialBrandShareOfVoiceValidator
>;

export type CommercialBrandSection = Static<
  typeof CommercialBrandSectionValidator
>;
