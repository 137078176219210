import React, { useState } from "react";
import "./DatePicker.scss";
import { DatePicker as DatePickerDs } from "@opsdti-global-component-library/amgen-design-system";
import {
  AutoUpdater,
  AutoUpdaterId,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import dayjs from "dayjs";
import { Label } from "common-components";
import { AdvancedTooltip } from "api";

type Props<T extends AutoUpdaterType> = {
  id?: AutoUpdaterId;
  label?: string;
  errorLabel?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  autoUpdater: AutoUpdater<T>;
  tooltip?: AdvancedTooltip;
};

const namespace = "rts-pa-date-picker";

export const DatePicker = <T extends AutoUpdaterType>(props: Props<T>) => {
  const { value, onChange } = useAutoUpdater<string | null, T>(
    props.autoUpdater,
    props.id
  );
  const [date, setDate] = useState<dayjs.Dayjs | null>(
    value ? dayjs(value) : props.required ? dayjs() : null
  );

  const onChangeWrapper = (dateValue: dayjs.Dayjs | null) => {
    setDate(dateValue);

    onChange(dateValue?.toISOString() || null);
  };

  return (
    <div className={namespace}>
      <Label tooltip={props.tooltip}>{props.label}</Label>
      <DatePickerDs
        value={date || undefined}
        onChange={onChangeWrapper}
        className={`${namespace}-selector`}
        allowClear={!props.required}
      />
    </div>
  );
};

export default DatePicker;
