import { EnvironmentalSustainabilityValidatorV2
 } from "./esg-item-v2/esg-v2";
 import { SummaryInfoValidator, OwnersValidator, LinkValidator, ArchetypeValidatorV2 } from "./common-v2";
 import { Record, Number, Array, Static, String } from "runtypes";

 export const EsgItemValidatorV2 = Record({
    id: Number,
    archetype: ArchetypeValidatorV2,
    agendaItemVersionId: Number,
    statusDrivers: Array(String),
    triggers : Array(String),
    summaryInfo: SummaryInfoValidator,
    owners: OwnersValidator,
    environmentalSustainability: EnvironmentalSustainabilityValidatorV2,
    links: Array(LinkValidator)
 })

 export type EsgItemV2 = Static <typeof EsgItemValidatorV2>