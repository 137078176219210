import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Loader,
  MetricCard
} from "@gitlab-rtsensing/component-library";
import { useNavigate } from "react-router";
import { LinkExternalIcon } from "@opsdti-global-component-library/amgen-design-system";
import "./SummaryTile.scss";
import { PermissionsContext } from "global/permissions";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { useNavLinks } from "global/use-nav-links";
import { TileConfig, getTileImage } from "api/updateHomepageImage";

export const Tile = () => {
  const navigator = useNavigate();
  const permissions = useContext(PermissionsContext);
  const tilePermissions = permissions.homeTile;
  const { links } = useNavLinks(permissions);
  const { data, status } = useQuery<TileConfig>(
    ["tile-image-url"],
    getTileImage
  );

  return (
    <MetricCard className="rts-pa-summary-tile">
      <>
        <MetricCard.Header>
          <MetricCard.Title title="Prioritized Agenda" />
        </MetricCard.Header>
        <MetricCard.Content className="pa-summary-tile-content">
          <>
            <div className="pa-summary-tile-image-main">
              <div className="pa-summary-tile-image-wrapper">
                {data && (
                  <Loader status={status}>
                    <img
                      className="pa-summary-tile-image"
                      src={data.imageUrl}
                      alt={"Prioritized Agenda"}
                    />
                  </Loader>
                )}
              </div>
            </div>

            <PermissionsWrapper permissions={tilePermissions.pageLink}>
              <Button
                className="pa-summary-tile-button"
                onClick={() => {
                  navigator("/prioritized-agenda");
                }}
                type="secondary"
                label={`Go To Prioritized Agenda Page`}
              />
            </PermissionsWrapper>

            <PermissionsWrapper permissions={tilePermissions.pdfs}>
              <Button
                className="pa-summary-tile-button"
                iconPosition="right"
                onClick={() => {
                  if (!links.ceoStaff.url) {
                    return;
                  }
                  window.open(links.ceoStaff.url, "_blank", "noreferrer");
                }}
                icon={<LinkExternalIcon height={12} width={12} />}
                type="secondary"
                label={`Go To Ceo Staff Prioritized Agenda`}
              />
              <Button
                className="pa-summary-tile-button"
                iconPosition="right"
                onClick={() => {
                  if (!links.functionalPa.url) {
                    return;
                  }
                  window.open(links.functionalPa.url, "_blank", "noreferrer");
                }}
                icon={<LinkExternalIcon height={12} width={12} />}
                type="secondary"
                label={`Go To Functional Prioritized Agendas`}
              />
            </PermissionsWrapper>
          </>
        </MetricCard.Content>
      </>
    </MetricCard>
  );
};

export default Tile;
