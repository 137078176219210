import { Number, Record, Static, Array, String } from "runtypes";
import { ArchetypeValidatorV2, LinkValidator, OwnersValidator, SummaryInfoValidator } from "./common-v2";
import { TalentDinbTalentSectionValidatorV2 } from "./talent-dinb-v2/talent-dinb-talent-v2";
import { TalentDinbDiversityInclusionBelongingSectionValidatorV2 } from "./talent-dinb-v2/talent-dinb-diversity-inclusion-belonging-v2";
import { TalentDinbLaborProductivitySectionValidatorV2 } from "./talent-dinb-v2/talent-dinb-labor-productivity-v2";

export const TalentDinbValidatorV2 = Record({
  agendaItemId: Number,
  archetype: ArchetypeValidatorV2,
  triggers: Array(String),
  statusDrivers: Array(String),
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  talent: TalentDinbTalentSectionValidatorV2.nullable(),
  diversityInclusionBelonging:
    TalentDinbDiversityInclusionBelongingSectionValidatorV2.nullable(),
  laborProductivity: TalentDinbLaborProductivitySectionValidatorV2.nullable(),
  links: Array(LinkValidator)
});

export type TalentDinbV2 = Static<typeof TalentDinbValidatorV2>;
