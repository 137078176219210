import {
  AdvancedTooltipValidator,
  StatusValidator
} from "api/common";
import { LinkValidator } from "api/common-v2";
import { Array, Boolean, Number, Record, Static, String } from "runtypes";
import { SupplyKeyInsightsValidatorV2 } from "./esg-common-v2";

export const TotalCarbonWasteWaterReductionOffsetValidatorV2 = Record({
  tooltip: AdvancedTooltipValidator,
  status: StatusValidator,
  supplyKeyInsights: SupplyKeyInsightsValidatorV2,
  title: String,
  carbon: Number,
  waste: Number,
  water: Number,
  links: Array(LinkValidator),
  statusDriver: Boolean,
});

export type TotalCarbonWasteWaterReductionOffsetV2 = Static<
  typeof TotalCarbonWasteWaterReductionOffsetValidatorV2
>;
