import { InactiveSectionValidator, StatusValidator, ExternalLinkValidator } from "api/common";
import { Array, Number, Record, Static, String } from "runtypes";

const TalentDinbLaborProductivityRevenuePerLnbValidator = Record({
  status: StatusValidator,
  forecastMonth: String, //e.g. "June"
  forecastYear: Number,
  planKey: String,
  forecastKey: String,
  actualsKey: String,
  chart: Array(
    Record({
      label: String,
      actuals: Number.nullable(),
      plan: Number.nullable(),
      forecast: Number.nullable()
    })
  ),
  externalLinks: Array(ExternalLinkValidator)
});

export type TalentDinbLaborProductivityRevenuePerLnb = Static<
  typeof TalentDinbLaborProductivityRevenuePerLnbValidator
>;

export const TalentDinbLaborProductivitySectionValidator = Record({
  revenuePerLnb: TalentDinbLaborProductivityRevenuePerLnbValidator.Or(InactiveSectionValidator)
});

export type TalentDinbLaborProductivitySection = Static<
  typeof TalentDinbLaborProductivitySectionValidator
>;
