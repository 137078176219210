import React, { useContext } from "react";
import { Tooltip } from "common-components/tooltip/Tooltip";
import { useQuery } from "@tanstack/react-query";
import { CheckoutStatus, getCheckoutStatus } from "api/conflict-management-v2";
import { Button } from "@opsdti-global-component-library/amgen-design-system";
import { ButtonProps } from "@opsdti-global-component-library/amgen-design-system/lib/components/elements/button/index";
import TooltipContent from "common-components/tooltip-content/TooltipContent";
import { useOktaUser } from "global/use-okta-user";
import { getUsernameFromEmail } from "utils";
import { PermissionsContext } from "global/permissions";

export const CheckoutButtonWithTooltipV2 = (props: ButtonProps) => {
  const user = useOktaUser();
  const perms = useContext(PermissionsContext).grid;

  const { data: checkoutStatus } = useQuery(
    ["checkout-status"],
    getCheckoutStatus,
    {
      enabled: perms.edit
    }
  );

  const isDisabled =
    checkoutStatus?.status === CheckoutStatus.locked &&
    checkoutStatus.username !== getUsernameFromEmail(user?.email);

  const button = <Button {...props} disabled={props.disabled || isDisabled} />;

  if (isDisabled) {
    return (
      <Tooltip tooltipIcon={button}>
        <TooltipContent>
          Prioritized Agenda is currently checked out by{" "}
          {checkoutStatus.username}
        </TooltipContent>
      </Tooltip>
    );
  }

  return button;
};

export default CheckoutButtonWithTooltipV2;
