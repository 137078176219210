import React from "react";
import "./BiosimilarsPipelineMetricsGroupV2.scss";
import { BiosimilarsPipelineGroupV2 } from "api/biosimilars-item-v2/biosimilars-pipeline-group-v2";
import ModalSection from "common-components/modal-section/ModalSection";
import BiosimilarsPipelineMetricsPlannedMarketReadinessSectionV2 from "./planned-market-readiness-section-v2/BiosimilarsPlannedMarketReadinessSectionV2";

type Props = {
  data?: BiosimilarsPipelineGroupV2 | undefined | null;
};

const namespace = "rts-pa-biosimilars-modal-pipeline-metrics-v2";

const BiosimilarsPipelineMetricsGroupV2 = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection title="Pipeline Metrics" className={namespace}>
      <div className={`${namespace}-container`}>
        <BiosimilarsPipelineMetricsPlannedMarketReadinessSectionV2
          data={props.data.plannedMarketReadiness}
        />
      </div>
    </ModalSection>
  );
};

export default BiosimilarsPipelineMetricsGroupV2;
