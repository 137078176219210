import { Array, Number, Record, Static, String } from "runtypes";
import {
  ExternalLinkValidator,
  InactiveSectionValidator,
  OwnersValidator,
  StatusValidator
} from "../common";

const CommercialSupplyLocationSectionValidator = Record({
  status: StatusValidator,
  text: String.nullable()
});

const CommercialSupplyLocationValidator = Record({
  title: String,
  status: StatusValidator,
  owners: OwnersValidator,
  scope: CommercialSupplyLocationSectionValidator,
  timeline: CommercialSupplyLocationSectionValidator,
  value: CommercialSupplyLocationSectionValidator,
  cost: CommercialSupplyLocationSectionValidator,
  risk: CommercialSupplyLocationSectionValidator,
  externalLinks: Array(ExternalLinkValidator)
});

const CommercialSupplyCogsReductionValidator = Record({
  status: StatusValidator
});

const CommercialSupplyInventoryBelowTargetValidator = Record({
  status: StatusValidator,
  fdp: Record({
    value: Number,
    status: StatusValidator,
    statusText: String,
    reasonCodes: Array(String),
    countries: Array(String)
  }),
  externalLinks: Array(ExternalLinkValidator)
});

export const CommercialSupplySectionValidator = Record({
  locations: Array(
    CommercialSupplyLocationValidator.Or(InactiveSectionValidator)
  ),
  cogsReduction: CommercialSupplyCogsReductionValidator.Or(
    InactiveSectionValidator
  ).nullable(),
  inventoryBelowTarget: CommercialSupplyInventoryBelowTargetValidator.Or(
    InactiveSectionValidator
  ).nullable()
});

export type CommercialSupplyLocationSection = Static<
  typeof CommercialSupplyLocationValidator
>;
export type CommercialSupplyCogsReductionSection = Static<
  typeof CommercialSupplyCogsReductionValidator
>;
export type CommercialSupplyInventoryBelowTargetSection = Static<
  typeof CommercialSupplyInventoryBelowTargetValidator
>;

export type CommercialSupplySection = Static<
  typeof CommercialSupplySectionValidator
>;
