import React, { useMemo } from "react";
import "./SubmissionsTableFilters.scss";
import { SubmissionStatus } from "api/submission/submission-common";
import produce from "immer";
import DateRangePicker from "../../../common-components/date-range-picker/DateRangePicker";
import { SubmissionsTable } from "api";
import SearchInput from "common-components/search-input/SearchInput";
import dayjs from "dayjs";
import { SelectSimple, Option, Label } from "common-components";

const namespace = "rts-pa-submissions-table-filters";

export type DdlFilterKey = "status" | "owner";

const statusFilterItems: Option[] = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Ready for Review",
    value: "submitted"
  },
  {
    label: "Requested",
    value: "created"
  },
  {
    label: "Reviewed",
    value: "approved"
  }
];

export type Filters = {
  status: SubmissionStatus | "";
  owner: string;
  dateRange: dayjs.Dayjs[];
  name: string;
};

type Props = {
  filters: Filters;
  submissionsData?: SubmissionsTable;
  onChange: (filters: Filters) => void;
};

export const SubmissionsTableFilters = ({
  filters,
  submissionsData,
  onChange
}: Props) => {
  const onDropdownItemChange = (value: string, filterKey: DdlFilterKey) => {
    const newFilters = produce(filters, draftState => {
      if (filterKey === "status") {
        draftState.status = value as SubmissionStatus;
      }

      if (filterKey === "owner") {
        draftState.owner = value;
      }
    });

    onChange(newFilters);
  };

  const onDatePickerChange = (dateRange: dayjs.Dayjs[]) => {
    const newFilters = produce(filters, draftState => {
      draftState.dateRange = dateRange[0] && dateRange[1] ? dateRange : [];
    });

    onChange(newFilters);
  };

  const onSearchInputChange = (value: string) => {
    const newFilters = produce(filters, draftState => {
      draftState.name = value;
    });

    onChange(newFilters);
  };

  // populate business owner ddl
  const boFilterItems = useMemo<Option[]>(() => {
    const selectAllItem: Option = {
      label: "All Owners",
      value: ""
    };

    if (!submissionsData) {
      return [selectAllItem];
    }

    const distinctOwners = submissionsData
      .map(submission => submission.assignee)
      .filter(
        (owner, index, arr) =>
          index === arr.findIndex(o => o.email === owner.email)
      )
      .sort((a, b) => b.fullName.localeCompare(a.fullName));

    return [
      selectAllItem,
      ...distinctOwners.map(o => ({
        label: o.fullName,
        value: o.email
      }))
    ];
  }, [submissionsData]);

  return (
    <div className={namespace}>
      <Label className={`${namespace}-title`}>FILTER BY:</Label>
      <SelectSimple
        label="Status"
        className={`${namespace}-status-dropdown`}
        options={statusFilterItems}
        defaultValue=""
        onChange={(value: string) => onDropdownItemChange(value, "status")}
      />
      <SelectSimple
        label="Business Owner"
        className={`${namespace}-business-owner-dropdown`}
        options={boFilterItems}
        defaultValue=""
        onChange={(value: string) => onDropdownItemChange(value, "owner")}
        placeholder="Select an owner"
      />
      <DateRangePicker
        allowEmpty={[true, true]}
        className={`${namespace}-date-picker`}
        label="Date Range"
        format="MMM, DD YYYY"
        placeholder={["Start date", "End date"]}
        onChange={onDatePickerChange}
      />
      <SearchInput
        label="Item name search"
        placeholder="Enter item name"
        onChange={onSearchInputChange}
      />
    </div>
  );
};

export default SubmissionsTableFilters;
