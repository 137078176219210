import {
  AdvancedTooltipValidator,
  ChartItemValidator,
  StatusValidator,
} from "api/common";
import { LinkValidator } from "api/common-v2";
import { Array, Boolean, Record, Static, String } from "runtypes";

const TalentDinbTalentAmgenOverallEngagementValidatorV2 = Record({
  status: StatusValidator,
  title: String,
  tooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbTalentAmgenOverallEngagementV2 = Static<
  typeof TalentDinbTalentAmgenOverallEngagementValidatorV2
>;

const TalentDinbTalentAmgenTotalTurnoverValidatorV2 = Record({
  status: StatusValidator,
  title: String,
  tooltip: AdvancedTooltipValidator,
  chartTooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbTalentAmgenTotalTurnoverV2 = Static<
  typeof TalentDinbTalentAmgenTotalTurnoverValidatorV2
>;

const TalentDinbAmgenRevenueValidatorV2 = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator,
  chartTooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  links: Array(LinkValidator)
})

export type TalentDinbTalentAmgenRevenueV2 = Static<
  typeof TalentDinbAmgenRevenueValidatorV2
>;

export const TalentDinbTalentSectionValidatorV2 = Record({
  amgenOverallEngagement: TalentDinbTalentAmgenOverallEngagementValidatorV2.nullable(),
  amgenTotalTurnover: TalentDinbTalentAmgenTotalTurnoverValidatorV2.nullable(),
  amgenRevenue: TalentDinbAmgenRevenueValidatorV2.nullable()
});

export type TalentDinbTalentSectionV2 = Static<
  typeof TalentDinbTalentSectionValidatorV2
>;
