import React from "react";
import "./ComModalSupplyMetrics.scss";
import { CommercialSupplySection } from "api";
import ModalSection from "common-components/modal-section/ModalSection";
import ComModalSupplyLocation from "./location/ComModalSupplyLocation";
import ComModalSupplyCogsReduction from "./cogs-reduction/ComModalSupplyCogsReduction";
import ComModalSupplyInventoryBelowTarget from "./inventory-below-target/ComModalSupplyInventoryBelowTarget";

type Props = {
  data: CommercialSupplySection | undefined | null;
};

const ComModalSupplyMetrics = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection
      title="Supply Metrics"
      className="rts-pa-commercial-modal-supply-metrics"
    >
      <>
        {props.data.locations.map((l, i) => (
          <ComModalSupplyLocation key={i} data={l} />
        ))}
      </>
      <ComModalSupplyCogsReduction data={props.data.cogsReduction} />
      <ComModalSupplyInventoryBelowTarget
        data={props.data.inventoryBelowTarget}
      />
    </ModalSection>
  );
};

export default ComModalSupplyMetrics;
