import React from "react";
import "./AIModalSectionEngagementScore.scss";
import {
  AccordionWithStatus,
  getAdvancedTooltipContent
} from "common-components";
import { AgendaItemSectionEngagementScore } from "api/agenda-item/sections";
import dayjs from "dayjs";
import { format } from "utils";
import AIModalSectionEngagementScoreMetric from "./metric/AIModalSectionEngagementScoreMetric";

const namespace = "rts-pa-agenda-item-modal-section-engagement-score";

type Props = {
  agendaItemName: string;
  section: AgendaItemSectionEngagementScore;
};

export const AIModalSectionEngagementScore = (props: Props) => {
  return (
    <AccordionWithStatus
      title={props.section.title}
      status={props.section.status}
      className={namespace}
      tooltipContent={getAdvancedTooltipContent(props.section.tooltip)}
      externalLinks={props.section.links}
      accordionSyncKey="AgendaItemModal"
      accordionSyncId={props.section.id}
    >
      <div className={`${namespace}-container`}>
        <AIModalSectionEngagementScoreMetric
          label={props.agendaItemName}
          value={props.section.productEngagementScore}
          date={dayjs(props.section.date).format("MMM YYYY")}
          status={props.section.status}
          styleType="primary"
        />
        <AIModalSectionEngagementScoreMetric
          label="VS"
          value={format.simple(props.section.productChange, {
            showPlusSign: true
          })}
          date={dayjs(props.section.previousDate).format("MMM YYYY")}
          styleType="secondary"
        />
        <AIModalSectionEngagementScoreMetric
          label="Amgen Average"
          value={props.section.amgenAverage}
          styleType="primary"
        />
        <AIModalSectionEngagementScoreMetric
          label="general industries BENCHMARK"
          value={props.section.benchmark}
          styleType="secondary"
        />
      </div>
    </AccordionWithStatus>
  );
};

export default AIModalSectionEngagementScore;
