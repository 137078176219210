import { EnvironmentalSustainabilityValidator } from "api/esg-item/esg";
import {
  SummaryInfoValidator,
  OwnersValidator,
  ExternalLinkValidator
} from "api/common";
import { Record, Number, Array, Static } from "runtypes";

export const EsgItemValidator = Record({
  agendaItemId: Number,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  environmentalSustainability: EnvironmentalSustainabilityValidator,
  externalLinks: Array(ExternalLinkValidator)
});

export type EsgItem = Static<typeof EsgItemValidator>;
