import {
  AdvancedTooltipValidator,
  StatusValidator,
  StatusWithAcceleratedValidator
} from "api/common";
import { Array, Record, Static, String } from "runtypes";

const BiosimilarPipelinePlannedMarketReadinessProject = Record({
  id: String,
  name: String,
  status: StatusWithAcceleratedValidator,
  statusTooltip: AdvancedTooltipValidator,
  readinessDate: String,
  region: String
});

export const BiosimilarPipelinePlannedMarketReadinessSectionValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator,
  projects: Array(BiosimilarPipelinePlannedMarketReadinessProject)
});

export type BiosimilarPipelinePlannedMarketReadinessSection = Static<
  typeof BiosimilarPipelinePlannedMarketReadinessSectionValidator
>;
