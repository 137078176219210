import { Array, Literal, Record, Static, String } from "runtypes";
import { AgendaItemSectionValidator } from "../agenda-item-common";
import {
  AdvancedTooltipValidator,
  ExternalLinkValidator,
  OwnersValidator,
  PipelineEnrollmentStatusValidator,
  StatusWithAcceleratedValidator
} from "api/common";

//TODO JCG: rename from "section" -- found out what data means
const AgendaItemSectionPipelineProjectSectionValidator = Record({
  title: String,
  textLines: Array(String),
  date: String.nullable(),
  status: StatusWithAcceleratedValidator.nullable(),
  statusTooltip: AdvancedTooltipValidator.nullable()
});

export type AgendaItemSectionPipelineProjectSection = Static<
  typeof AgendaItemSectionPipelineProjectSectionValidator
>;

const AgendaItemSectionPipelineProjectStudyEnrollmentValidator = Record({
  textLines: Array(String),
  date: String,
  status: PipelineEnrollmentStatusValidator,
  statusTooltip: AdvancedTooltipValidator.nullable()
});

export type AgendaItemSectionPipelineProjectStudyEnrollment = Static<
  typeof AgendaItemSectionPipelineProjectStudyEnrollmentValidator
>;

export const AgendaItemSectionPipelineProjectValidator =
  AgendaItemSectionValidator.extend({
    type: Literal("pipelineProject"),
    owners: OwnersValidator.nullable(),
    sections: Array(AgendaItemSectionPipelineProjectSectionValidator),
    studyEnrollments: Array(
      AgendaItemSectionPipelineProjectStudyEnrollmentValidator
    ).nullable(),
    links: Array(ExternalLinkValidator)
  });

export type AgendaItemSectionPipelineProject = Static<
  typeof AgendaItemSectionPipelineProjectValidator
>;
