import React from "react";
import "./AIModalSectionInventoryBelowTarget.scss";
import {
  AccordionWithStatus,
  getAdvancedTooltipContent
} from "common-components";
import { AgendaItemSectionInventoryBelowTarget } from "api/agenda-item/sections";
import StatusIcon from "common-components/status-icon/StatusIcon";
import AIModalSectionInventoryBelowTargetLineItem from "./line-item/AIModalSectionInventoryBelowTargetLineItem";

const namespace = "rts-pa-agenda-item-modal-section-inventory-below-target";

type Props = {
  section: AgendaItemSectionInventoryBelowTarget;
};

export const AIModalSectionInventoryBelowTarget = (props: Props) => {
  return (
    <AccordionWithStatus
      title={props.section.title}
      status={props.section.status}
      tooltipContent={getAdvancedTooltipContent(props.section.tooltip)}
      className={namespace}
      accordionSyncKey="AgendaItemModal"
      accordionSyncId={props.section.id}
      externalLinks={props.section.links}
    >
      <div className={`${namespace}-content`}>
        <span className={`${namespace}-label`}>SKUs Below Min. Target</span>
        <AIModalSectionInventoryBelowTargetLineItem
          label="FDP"
          text={
            <div className={`${namespace}-fdp-value-container`}>
              <span className={`${namespace}-metrics-icon`}>
                <StatusIcon status={props.section.fdp.status} />
              </span>
              <span>{props.section.fdp.value}</span>
            </div>
          }
          bolder
        />
        <AIModalSectionInventoryBelowTargetLineItem
          label="status"
          text={props.section.fdp.statusText}
        />
        <AIModalSectionInventoryBelowTargetLineItem
          label="reason codes"
          text={props.section.fdp.reasonCodes.join(", ")}
        />
        <AIModalSectionInventoryBelowTargetLineItem
          label="countries"
          text={props.section.fdp.countries.join(", ")}
        />
      </div>
    </AccordionWithStatus>
  );
};

export default AIModalSectionInventoryBelowTarget;
