import React from "react";
import "./TalentDinbModalDinbMetricsV2.scss";
import ModalSection from "common-components/modal-section/ModalSection";
import { TalentDinbDiversityInclusionBelongingSectionV2 } from "api/talent-dinb-v2/talent-dinb-diversity-inclusion-belonging-v2";
import TalentDinbModalDinbWomenInLeadershipV2 from "./women-in-leadership-v2/TalentDinbModalDinbWomenInLeadershipV2";

type Props = {
  data: TalentDinbDiversityInclusionBelongingSectionV2 | undefined | null;
};

const TalentDinbModalDinbMetricsV2 = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection
      title="Diversity Inclusion & Belonging (DI&B) Metrics"
      className="rts-pa-talent-dinb-modal-dinb-metrics-v2"
    >
      <div className="rts-pa-talent-dinb-modal-dinb-metrics-container-v2">
        <TalentDinbModalDinbWomenInLeadershipV2
          data={props.data.womenInLeadership}
        />
      </div>
    </ModalSection>
  );
};

export default TalentDinbModalDinbMetricsV2;
