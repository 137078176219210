import {
  Array,
  Boolean,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union
} from "runtypes";
import { StatusValidator } from "./common";

export const ArchetypeValidatorV2 = Union(
  //make all archetypes lower case
  Literal("standalone"),
  Literal("commercial"),
  Literal("pipeline"),
  Literal("talent_dinb"),
  Literal("esg"),
  Literal("biosimilars")
);

export type ArchetypeV2 = Static<typeof ArchetypeValidatorV2>;

export const StatusTypeValidatorV2 = Union(
  Literal("manual"),
  Literal("automatic")
);

export type StatusTypeV2 = Static<typeof StatusTypeValidatorV2>;

export const OwnerValidator = Record({
  id: Number,
  text: String,
  order: Number
});

export type Owner = Static<typeof OwnerValidator>;

export const OwnersValidator = Record({
  ceoStaffSponsors: Array(OwnerValidator),
  operatingTeamOwners: Array(OwnerValidator)
});

export type Owners = Static<typeof OwnersValidator>;

export const LinkValidator = Record({
  id: Number,
  label: String,
  href: String,
  isSensingExternal: Boolean,
  order: Number
});

export type Link = Static<typeof LinkValidator>;

export const SummaryInfoValidator = Record({
  status: StatusValidator,
  reason: String,
  statusDrivers: Array(String),
  scope: String,
  keyInsights: String
});

export type SummaryInfo = Static<typeof SummaryInfoValidator>;

export const AgendaItemValidatorV2 = Record({
  id: Number,
  agendaItemVersionId: Number,
  name: String,
  archetype: ArchetypeValidatorV2,
  value: Number,
  focus: Number,
  order: Number,
  status: StatusValidator.nullable()
});

export type AgendaItemV2 = Static<typeof AgendaItemValidatorV2>;
