import React from "react";
import "./ComModalBrandMetrics.scss";
import { CommercialBrandSection } from "api";
import ModalSection from "common-components/modal-section/ModalSection";
import ComModalBrandSalesDemand from "./sales-demand/ComModalBrandSalesDemand";
import ComModalBrandIdnAccountWins from "./idn-account-wins/ComModalBrandIdnAccountWins";
import ComModalBrandNbrxFulfillment from "./nbrx-fulfillment/ComModalBrandNbrxFulfillment";
import ComModalBrandNbrxVolume from "./nbrx-volume/ComModalBrandNbrxVolume";
import ComModalBrandShareOfVoice from "./share-of-voice/ComModalBrandShareOfVoice";
import ComModalBrandPsoBioNaiveShare from "./pso-bio-naive-share/ComModalBrandPsoBioNaiveShare";
import ComModalBrandSpecPharmNbrxCommFulfillRate from "./spec-pharm-nbrx-comm-fulfill-rate/ComModalBrandSpecPharmNbrxCommFulfillRate";
import ComModalBrandCopayEnrollmentRate from "./copay-enrollment-rate/ComModalBrandCopayEnrollmentRate";
import ComModalBrandTargetsAtFrequency from "./targets-at-frequency/ComModalBrandTargetsAtFrequency";
import ComModalBrandFrequency from "./frequency/ComModalBrandFrequency";
import ComModalCommonSalesDemandLite from "../common/ComModalCommonSalesDemandLite";

type Props = {
  data: CommercialBrandSection | undefined | null;
};

const ComModalBrandMetrics = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection
      title="Brand Metrics"
      className="rts-pa-commercial-modal-brand-metrics"
    >
      <>
        {props.data.salesDemandLiteSections.map((sdls, i) => (
          <ComModalCommonSalesDemandLite key={i} data={sdls} />
        ))}
      </>
      <>
        {props.data.salesDemandSections.map((sds, i) => (
          <ComModalBrandSalesDemand key={i} data={sds} />
        ))}
      </>
      <ComModalBrandIdnAccountWins data={props.data.idnAccountWins} />
      <ComModalBrandNbrxFulfillment data={props.data.nbrxFulfillment} />
      <ComModalBrandNbrxVolume data={props.data.nbrxVolume} />
      <ComModalBrandPsoBioNaiveShare data={props.data.psoBioNaiveShare} />
      <ComModalBrandSpecPharmNbrxCommFulfillRate
        data={props.data.specPharmNbrxCommFulfillRate}
      />
      <ComModalBrandCopayEnrollmentRate data={props.data.copayEnrollmentRate} />
      <ComModalBrandTargetsAtFrequency data={props.data.targetsAtFrequency} />
      <ComModalBrandFrequency data={props.data.frequency} />
      <ComModalBrandShareOfVoice data={props.data.shareOfVoice} />
    </ModalSection>
  );
};

export default ComModalBrandMetrics;
