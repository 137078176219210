/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Home from "./home/Home";
import SubmissionsPage from "./submissions/SubmissionsPage";
import ChangeLogPage from "./change-log/ChangeLogPage";
import Tile from "./summary-tile/SummaryTile";
import { LoginCallback } from "@okta/okta-react";
import { getAgendaId, getKeyEventsId } from "utils/multitenancy";
import KeyEventsPage from "./key-events/KeyEventsPage";
import UnauthorizedPage from "./unauthorized/Unauthorized";

const pages = {
  path: "/prioritized-agenda",
  component: undefined as unknown as JSX.Element, //undefined needed to not create route, but casting needed for cleaner updateChildrenProperties

  grid: {
    path: "/grid/:agendaId",
    go(agendaId = "0", isLimitedView = false): string {
      let pathParamsObj: { [key: string]: string } | undefined = undefined;
      let queryParamsObj: { [key: string]: string } | undefined = undefined;

      if (agendaId && agendaId !== "0") {
        pathParamsObj = { agendaId: agendaId.toString() };
      }

      if (isLimitedView) {
        queryParamsObj = { limited_view: isLimitedView.toString() };
      }

      return addParams(this.path, pathParamsObj, queryParamsObj);
    },
    component: <Home />,

    edit: {
      path: "/edit",
      go(): string {
        return addParams(this.path);
      },
      component: undefined,

      addItemModal: {
        path: "/add-item-modal",
        go(focus: number, value: number): string {
          return addParams(this.path, undefined, {
            focus: focus.toString(),
            value: value.toString()
          });
        },
        component: undefined
      },

      editItemModal: {
        path: "/edit-item-modal/:agendaItemId",
        go(agendaItemId: number): string {
          return addParams(this.path, {
            agendaItemId: agendaItemId.toString()
          });
        },
        component: undefined
      },

      agendaItemBuilderModal: {
        path: "/agenda-item-builder-modal",
        go(): string {
          return addParams(this.path);
        },
        component: undefined
      },

      rearrange: {
        path: "/rearrange",
        go(): string {
          return addParams(this.path);
        },
        component: undefined
      }
    },

    submissions: {
      path: "/submissions",
      go(): string {
        return addParams(this.path);
      },
      component: <SubmissionsPage />
    },

    changeLog: {
      path: "/change-log",
      go(): string {
        return addParams(this.path);
      },
      component: <ChangeLogPage />
    },

    addAgendaModal: {
      path: "/add-agenda-modal",
      go(): string {
        return addParams(this.path);
      },
      component: undefined
    },

    editAgendaModal: {
      path: "/edit-agenda-modal",
      go(): string {
        return addParams(this.path);
      },
      component: undefined
    },

    globalConfigModal: {
      path: "/global-config-modal",
      go(): string {
        return addParams(this.path);
      },
      component: undefined
    },

    updateHomepageImageModal: {
      path: "/update-homepage-image-modal",
      go(): string {
        return addParams(this.path);
      },
      component: undefined
    },

    standaloneModal: {
      path: "/standalone-modal/:agendaItemId",
      go(agendaItemId: number): string {
        return addParams(this.path, {
          agendaItemId: agendaItemId.toString()
        });
      },
      component: undefined
    },

    commercialModal: {
      path: "/commercial-modal/:agendaItemId",
      go(agendaItemId: number): string {
        return addParams(this.path, {
          agendaItemId: agendaItemId.toString()
        });
      },
      component: undefined
    },

    pipelineModal: {
      path: "/pipeline-modal/:agendaItemId",
      go(agendaItemId: number): string {
        return addParams(this.path, {
          agendaItemId: agendaItemId.toString()
        });
      },
      component: undefined
    },

    talentDinbModal: {
      path: "/talentdinb-modal/:agendaItemId",
      go(agendaItemId: number): string {
        return addParams(this.path, {
          agendaItemId: agendaItemId.toString()
        });
      },
      component: undefined
    },

    horizonModal: {
      path: "/horizon-modal/:agendaItemId",
      go(agendaItemId: number): string {
        return addParams(this.path, {
          agendaItemId: agendaItemId.toString()
        });
      },
      component: undefined
    },

    esgModal: {
      path: "/esg-modal/:agendaItemId",
      go(agendaItemId: number): string {
        return addParams(this.path, {
          agendaItemId: agendaItemId.toString()
        });
      },
      component: undefined
    },

    biosimilarsModal: {
      path: "/biosimilar-modal/:agendaItemId",
      go(agendaItemId: number): string {
        return addParams(this.path, {
          agendaItemId: agendaItemId.toString()
        });
      },
      component: undefined
    }
  },

  keyEvents: {
    path: "/key-events/:keyEventsId",
    go(keyEventsId = "0", isLimitedView = false): string {
      let pathParamsObj: { [key: string]: string } | undefined = undefined;
      let queryParamsObj: { [key: string]: string } | undefined = undefined;

      if (keyEventsId && keyEventsId !== "0") {
        pathParamsObj = { keyEventsId: keyEventsId.toString() };
      }

      if (isLimitedView) {
        queryParamsObj = { limited_view: isLimitedView.toString() };
      }

      return addParams(this.path, pathParamsObj, queryParamsObj);
    },
    component: <KeyEventsPage />,

    addItemModal: {
      path: "/add-item-modal",
      go(): string {
        return addParams(this.path);
      },
      component: undefined
    },

    editItemModal: {
      path: "/edit-item-modal/:rowItemId",
      go(rowItemId: number): string {
        return addParams(this.path, {
          rowItemId: rowItemId.toString()
        });
      },
      component: undefined
    }
  },

  tile: {
    path: "/tile",
    component: Tile
  },

  loginCallback: {
    path: "/implicit/callback",
    component: <LoginCallback />
  },

  unauthorized: {
    path: "/unauthorized",
    go(): string {
      return this.path;
    },
    component: <UnauthorizedPage />
  }
};

function addParams(
  str: string,
  pathParams?: { [key: string]: string },
  queryParams?: { [key: string]: string }
): string {
  let newStr = str.toString();

  if (pathParams) {
    const paramKeys = Object.keys(pathParams);
    for (let i = 0; i < paramKeys.length; i++) {
      const paramKey = paramKeys[i];
      newStr = newStr.replace(`:${paramKey}`, pathParams[paramKey]);
    }
  }

  // replace :agenda_id if not already replaced by pathParams
  newStr = newStr.replace(":agendaId", getAgendaId());
  newStr = newStr.replace(":keyEventsId", getKeyEventsId());

  if (queryParams) {
    const paramKeys = Object.keys(queryParams);
    for (let i = 0; i < paramKeys.length; i++) {
      const prefix = i === 0 ? "?" : "&";
      const paramKey = paramKeys[i];
      const paramValue = queryParams[paramKey];
      newStr += `${prefix}${paramKey}=${paramValue}`;
    }
  }

  return newStr;
}

const skippedProperties = ["path", "go", "component"];

(function updateChildrenProperties(
  pathObj,
  parentPath: string,
  parentComponent: JSX.Element
) {
  //update path
  const currentPath = parentPath + (pathObj.path || "");
  pathObj.path = currentPath; // eslint-disable-line no-param-reassign
  pathObj.component = pathObj.component || parentComponent; // eslint-disable-line no-param-reassign

  for (const [key, value] of Object.entries(pathObj)) {
    if (skippedProperties.includes(key)) continue;

    updateChildrenProperties(value as any, currentPath, pathObj.component);
  }
})(pages, "", undefined as unknown as JSX.Element);

export default pages;
