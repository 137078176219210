import React from "react";
import "./DateRangePicker.scss";
import {
  RangePicker,
  RangePickerProps
} from "@opsdti-global-component-library/amgen-design-system";
import dayjs from "dayjs";
import { Label } from "common-components";

type Props = {
  label: string;
  onChange: (dateRange: dayjs.Dayjs[]) => void;
} & Omit<RangePickerProps, "onChange">;

const namespace = "rts-pa-date-range-picker";

export const DateRangePicker = ({ label, onChange, ...rest }: Props) => {
  return (
    <div className={namespace}>
      <Label>{label}</Label>
      <RangePicker
        {...rest}
        onChange={dates => {
          if (!dates || !dates[0] || !dates[1]) {
            onChange([]);
            return;
          }

          const startDateString = dates[0].format("YYYY/MM/DD");
          const endDateString = dates[1].format("YYYY/MM/DD");

          const startDate: dayjs.Dayjs = dayjs.tz(startDateString); // America/Los_Angeles
          const endDate: dayjs.Dayjs = dayjs.tz(endDateString); // America/Los_Angeles

          onChange([startDate, endDate]);
        }}
        className={`${namespace}-range`}
      />
    </div>
  );
};

export default DateRangePicker;
