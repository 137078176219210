import { Array, Number, Record, Static, String } from "runtypes";

export const AgendaListItemValidator = Record({
  agendaId: Number,
  title: String
});

export type AgendaListItem = Static<typeof AgendaListItemValidator>;

export const AgendaListValidator = Record({
  agendas: Array(AgendaListItemValidator)
});

export type AgendaList = Static<typeof AgendaListValidator>;
