import { StatusValidator } from "api/common";
import { LinkValidator } from "api/common-v2";
import { Array, Boolean, Number, Record, Static, String } from "runtypes";

const TalentDinbDiversityInclusionBelongingWomenInLeadershipValidatorV2 = Record({
  status: StatusValidator,
  title: String,
  executive: Number,
  management: Number,
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbDiversityInclusionBelongingWomenInLeadershipV2 = Static<
  typeof TalentDinbDiversityInclusionBelongingWomenInLeadershipValidatorV2
>;

export const TalentDinbDiversityInclusionBelongingSectionValidatorV2 = Record({
  womenInLeadership:
    TalentDinbDiversityInclusionBelongingWomenInLeadershipValidatorV2
});

export type TalentDinbDiversityInclusionBelongingSectionV2 = Static<
  typeof TalentDinbDiversityInclusionBelongingSectionValidatorV2
>;
