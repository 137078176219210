import { Array, Literal, Record, Static, String } from "runtypes";
import { AgendaItemSectionValidator } from "../agenda-item-common";
import {
  ChartItemValidator,
  ExternalLinkValidator,
  StatusValidator
} from "api/common";

const AgendaItemSectionSalesDemandTableRowItemValidator = Record({
  label: String,
  value: String,
  status: StatusValidator.nullable()
});

const AgendaItemSectionSalesDemandTableRowValidator = Record({
  items: Array(AgendaItemSectionSalesDemandTableRowItemValidator)
});

const AgendaItemSectionSalesDemandTableValidator = Record({
  title: String,
  rows: Array(AgendaItemSectionSalesDemandTableRowValidator)
});

export type AgendaItemSectionSalesDemandTable = Static<
  typeof AgendaItemSectionSalesDemandTableValidator
>;

export const AgendaItemSectionSalesDemandValidator =
  AgendaItemSectionValidator.extend({
    type: Literal("salesDemand"),
    chartTitle: String.nullable(),
    chart: Array(ChartItemValidator),
    tables: Array(AgendaItemSectionSalesDemandTableValidator),
    links: Array(ExternalLinkValidator)
  });

export type AgendaItemSectionSalesDemand = Static<
  typeof AgendaItemSectionSalesDemandValidator
>;
