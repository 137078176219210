import { StatusValidator } from "api/common";
import { LinkValidator } from "api/common-v2";
import { Array, Boolean, Number, Record, Static, String } from "runtypes";

const TalentDinbLaborProductivityRevenuePerLnbChartValidatorV2 = Record({
  plan: Number.nullable(),
  label: String,
  actuals: Number.nullable(),
  forecast: Number.nullable()
})

export type TalentDinbLaborProductivityRevenuePerLnbChartV2 = Static<
  typeof TalentDinbLaborProductivityRevenuePerLnbChartValidatorV2
  >;

const TalentDinbLaborProductivityRevenuePerLnbValidatorV2 = Record({
  chart: Array(
    TalentDinbLaborProductivityRevenuePerLnbChartValidatorV2
  ),
  planKey: String,
  actualsKey: String,
  forecastKey: String,
  forecastYear: String,
  forecastMonth: String, //e.g. "June"
  status: StatusValidator,
  title: String,
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbLaborProductivityRevenuePerLnbV2 = Static<
  typeof TalentDinbLaborProductivityRevenuePerLnbValidatorV2
>;

export const TalentDinbLaborProductivitySectionValidatorV2 = Record({
  revenuePerLnb: TalentDinbLaborProductivityRevenuePerLnbValidatorV2
});

export type TalentDinbLaborProductivitySectionV2 = Static<
  typeof TalentDinbLaborProductivitySectionValidatorV2
>;
