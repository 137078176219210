import React from "react";
import "./ComModalPeopleEngagementScore.scss";
import {
  CommercialPeopleEngagementScoreSection,
  CommercialPeopleInactiveSection,
  Status
} from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import dayjs from "dayjs";
import { format } from "utils";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import { Label, Text } from "common-components";

const namespace = "rts-pa-commercial-modal-people-engagement-score";

type EngagementScoreProps = {
  label: string;
  date?: string;
  value: number | string;
  status?: Status;
  isLight?: boolean;
};

const PeopleEngagementScoreMetric = (props: EngagementScoreProps) => {
  return (
    <div className={`${namespace}-metric`}>
      <Label colorVariant={props.isLight ? "default" : "secondary"}>
        {props.label} {props.date ? props.date : null}
      </Label>
      <Text type={props.status} strong={!props.isLight}>
        {props.value}
      </Text>
    </div>
  );
};

type Props = {
  agendaItemTitle: string;
  data:
    | CommercialPeopleEngagementScoreSection
    | CommercialPeopleInactiveSection
    | undefined
    | null;
};

const ComModalPeopleEngagementScore = (props: Props) => {
  const title = "Engagement Score";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return (
      <AccordionWithStatus
        title={title}
        status="gray"
        tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      />
    );
  }

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={title}
      className={namespace}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      externalLinks={props.data.externalLinks}
      accordionSyncKey="CommercialModal"
    >
      <PeopleEngagementScoreMetric
        label={props.agendaItemTitle}
        value={props.data.productEngagementScore}
        date={dayjs(props.data.date).format("MMM YYYY")}
        status={props.data.status}
      />
      <PeopleEngagementScoreMetric
        label="VS"
        value={format.simple(props.data.productChange, { showPlusSign: true })}
        date={dayjs(props.data.previousDate).format("MMM YYYY")}
        isLight
      />
      <PeopleEngagementScoreMetric
        label="Amgen Average"
        value={props.data.amgenAverage}
      />
      <PeopleEngagementScoreMetric
        label="general industries BENCHMARK"
        value={props.data.benchmark}
        isLight
      />
    </AccordionWithStatus>
  );
};

export default ComModalPeopleEngagementScore;
