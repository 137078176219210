import { Record, Static } from "runtypes";
import { BiosimilarPipelinePlannedMarketReadinessSectionValidator } from "./biosimilar-planned-market-readiness-section";

export const BiosimilarPipelineGroupValidator = Record({
  plannedMarketReadiness:
    BiosimilarPipelinePlannedMarketReadinessSectionValidator
});

export type BiosimilarPipelineGroup = Static<
  typeof BiosimilarPipelineGroupValidator
>;
