import { String, Record, Static } from "runtypes";

export const SupplyKeyInsightsValidatorV2 = Record({
  assistanceNeeded: String.nullable(),
  owner: String.nullable(),
  rootCauseAction: String.nullable(),
  spotlight: String.nullable()
});

export type SupplyKeyInsightsType = Static<typeof SupplyKeyInsightsValidatorV2>;
