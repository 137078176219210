import { Array, Number, Record, Static, String } from "runtypes";
import {
  OwnersValidator,
  LinkValidator,
  SummaryInfoValidator,
  ArchetypeValidatorV2
} from "./common-v2";
import {
  NextApprovalLaunchE2lValidator,
  NextPriorityMilestoneValidator,
  PipelineStudyEnrollmentValidator
} from "./pipeline/pipeline-project";

export const PipelineItemValidatorV2 = Record({
  id: Number,
  agendaItemVersionId: Number,
  archetype: ArchetypeValidatorV2,
  triggers: Array(String),
  summaryInfo: SummaryInfoValidator,
  title: String,
  owners: OwnersValidator,
  nextApprovalLaunchE2L: NextApprovalLaunchE2lValidator,
  nextPriorityMilestone: NextPriorityMilestoneValidator,
  studyEnrollments: Array(PipelineStudyEnrollmentValidator),
  links: Array(LinkValidator)
});

export type PipelineItemV2 = Static<typeof PipelineItemValidatorV2>;
