import axios from "axios";
import { Array, Static, String } from "runtypes";

import {
  AgendaItemValidatorV2,
  LinkValidator,
  OwnersValidator,
  StatusTypeValidatorV2,
  SummaryInfoValidator
} from "./common-v2";
import { getAgendaId } from "utils/multitenancy";

const AgendaItemWithDetailsValidatorV2 = AgendaItemValidatorV2.omit(
  "status"
).extend({
  archetypeLinkId: String.nullable(),
  statusType: StatusTypeValidatorV2,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  links: Array(LinkValidator)
});

export type AgendaItemWithDetailsV2 = Static<
  typeof AgendaItemWithDetailsValidatorV2
>;

export const getAgendaItemV2 = async (
  id: number
): Promise<AgendaItemWithDetailsV2> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<AgendaItemWithDetailsV2>(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/agenda_item/${id}`
  );

  AgendaItemWithDetailsValidatorV2.check(result.data);

  return result.data;
};

export const postAgendaItemV2 = async (
  agendaItem: AgendaItemWithDetailsV2
): Promise<AgendaItemWithDetailsV2> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<AgendaItemWithDetailsV2>(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/agenda_item`,
    agendaItem
  );

  AgendaItemWithDetailsValidatorV2.check(result.data);

  return result.data;
};

export const putAgendaItemV2 = async (
  agendaItem: AgendaItemWithDetailsV2
): Promise<AgendaItemWithDetailsV2> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<AgendaItemWithDetailsV2>(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/agenda_item/${agendaItem.id}`,
    agendaItem
  );

  AgendaItemWithDetailsValidatorV2.check(result.data);

  return result.data;
};

export const deleteAgendaItemV2 = async (agendaItemId: number) => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.delete(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/agenda_item/${agendaItemId}`
  );

  return result;
};
